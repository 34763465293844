import { Optional } from "@shared/utils/optional";
import { array, boolean, InferType, number, object, string } from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { strictCoordinatesSchema } from "./Coordinates";
import { bonusExperienceSchema } from "./EntranceCode";

export const USER_PROFILE_COLLECTION_NAME = "users";

/** Fields the user is allowed to update directly. */
const USER_UPDATABLE_FIELDS: (keyof UserProfile)[] = [
  "bio",
  "email",
  "phone",
  "firstName",
  "lastName",
  "username",
  "verifiedTos",
  "allowEmails",
];

export type UserRole = "admin" | "contributor";

export const userProfileSchema = object({
  accountType: string()
    .oneOf(["magic", "walletConnect", "injected", ""])
    .notRequired()
    .default(""),
  id: string().default(""),
  bio: string().default(""),
  email: string().email().default(""),
  phone: string().default(""),
  firstName: string().default(""),
  lastName: string().default(""),
  username: string().default(""),
  addresses: object({
    eth: array(string()).default([]),
  }),
  passportId: string().optional().default(""),
  verifiedTos: boolean().default(false),
  allowEmails: boolean().default(false),
  theSpaceAlpha: boolean().default(false),
  createdAt: number().default(0),
  bonusExperiences: array(bonusExperienceSchema).default([]),
  entranceCodeId: string().default(""),
  /** Subscribed channels */
  channels: array(string())
    .required()
    .default([] as string[]),
  profileImage: string().default(""),
  role: string()
    .oneOf(["admin", "contributor", "guest"])
    .default("guest")
    .required() as RequiredStringSchema<"admin" | "contributor" | "guest">,
  /** coordinates of the channel for nudges. */
  coordinates: strictCoordinatesSchema
    .required()
    .default(strictCoordinatesSchema.getDefault()),
  /** if true, show a nudge when the map is over the coordinates on the general channel */
  nudge: boolean().required().default(false),
});

/** A schema for user-updatable profile fields (for use in forms) */
export const userUpdatableProfileSchema = userProfileSchema.pick(
  USER_UPDATABLE_FIELDS
);

/** User profile data, attached to a firebase user. */
export interface UserProfile extends InferType<typeof userProfileSchema> {
  addresses: { eth: string[] };
  role: "contributor" | "admin" | "guest";
}

/** The public data for a user. */
export type PublicUserProfile = {
  username: string;
  addresses: {
    eth: string[];
  };
};

/** Get the best name for a user profile. */
export const getUserProfileName = (userProfile: UserProfile | null) => {
  if (!userProfile) {
    return "";
  }

  const fullName = getUserProfileFullName(userProfile);
  if (fullName) {
    return fullName;
  }

  if (userProfile.username) {
    return userProfile.username;
  }

  if (userProfile.email) {
    return userProfile.email;
  }

  return userProfile.addresses?.eth[0] || "No Name";
};

/** Get the full name of a user. */
export const getUserProfileFullName = (userProfile: UserProfile) => {
  return [userProfile.firstName, userProfile.lastName]
    .filter((name) => !!name)
    .join(" ");
};

export const emptyUserProfile = userProfileSchema.getDefault() as UserProfile;

export const userHasAddress = (userProfile: UserProfile, address: string) => {
  if (!address) return false;
  return userProfile.addresses?.eth?.includes(address?.toLowerCase()) || false;
};

export const getFirstUserAddress = (
  userProfile: Optional<PublicUserProfile | UserProfile>
) => {
  if (!userProfile) return "";
  return userProfile.addresses?.eth?.[0] || "";
};

/** Validate user-editable fields for a user profile. */
export const validateUserProfileUpdate = (userProfile: any) => {
  // Validate the user profile without adding defaults.
  const validated = userUpdatableProfileSchema.validateSync(userProfile, {
    // Don't set defaults, which will override the existing values in the db.
    strict: true,
  }) as UserProfile;

  const keys = Object.keys(validated) as (keyof UserProfile)[];

  // Strip out any fields that are not user-editable.
  // Note that yup has a `stripUnknown` option, but it doesn't work with `strict: true`.
  keys.forEach((key) => {
    if (!USER_UPDATABLE_FIELDS.includes(key)) {
      delete validated[key];
    }
  }, {} as UserProfile);

  return validated;
};

export const getIsAdmin = (user: Optional<UserProfile>): boolean => {
  if (!user) return false;
  if (user.role === "admin") return true;
  return false;
};

export const getIsContributor = (user: Optional<UserProfile>): boolean => {
  if (!user) return false;
  if (user.role === "contributor") return true;
  return false;
};

export const getHasStudioAccess = (user: Optional<UserProfile>): boolean => {
  return getIsAdmin(user) || getIsContributor(user);
};
