import { BigNumber } from "ethers";

import { ArtPiece } from "./models/ArtPiece";

/** Find the nearest open edition art piece to a token ID. */
export const findOpenEdition = (
  tokenId: string,
  openEditions: ArtPiece[]
): ArtPiece | null => {
  const tokenIdNumber = BigNumber.from(tokenId);

  const nearestOpenEdition = openEditions
    .sort((a, b) => {
      const diff = BigNumber.from(a.tokenId)
        .sub(b.tokenId)
        // Descending
        .mul(-1);
      if (diff.gt(0)) return 1;
      if (diff.lt(0)) return -1;
      return 0;
    })
    .find((openEdition) => tokenIdNumber.gt(openEdition.tokenId));

  if (!nearestOpenEdition) return null;

  const bigIndex = tokenIdNumber.sub(nearestOpenEdition.tokenId);
  if (bigIndex.gt(1000)) return null;
  const index = bigIndex.toNumber();

  return {
    ...nearestOpenEdition,
    id: tokenId,
    tokenId,
    name: `${nearestOpenEdition.name} (OE-${index})`,
    openEditionIndex: index,
  };
};
