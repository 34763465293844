import { Artist } from "@shared/models/Artist";
import { ArtPiece } from "@shared/models/ArtPiece";

export const filterArtPiecesByArtist = (
  artist: Artist,
  artPieces: Record<string, ArtPiece>
) => {
  const filtered = Object.values(artPieces).filter((artPiece) => {
    return artPiece.artistId === artist.id;
  });

  return sortByEndDate(filtered);
};

export const filterArtPiecesLive = (artPieces: Record<string, ArtPiece>) => {
  const timeNow = new Date(Date.now());

  const filtered = Object.values(artPieces).filter((artPiece) => {
    if (!artPiece.activeAuction?.endDate) return false;

    const endTime = new Date(artPiece.activeAuction.endDate);

    return (
      endTime.getTime() > timeNow.getTime() &&
      artPiece.tokenId !== "777" &&
      artPiece.tokenId !==
        "8456350751317975846800924683986100177337207567287562046240586730923411985742"
    );
  });

  return sortByEndDate(filtered);
};

/** Sort a list of art pieces, so that the auctions ending soon are at the top, and auctions already ended are at the bottom. */
export const sortByEndDate = (artPieces: ArtPiece[]) => {
  return artPieces.sort((a, b) => {
    if (a.activeAuction?.endDate === b.activeAuction?.endDate) {
      return 0;
    }

    // A missing auction sorts to the end
    if (!b.activeAuction?.endDate) return -1;
    if (!a.activeAuction?.endDate) return 1;

    const aEndDate = new Date(a.activeAuction.endDate).getTime();
    const bEndDate = new Date(b.activeAuction.endDate).getTime();
    const now = Date.now();

    // A past auction sorts to the end.
    if (bEndDate < now) return -1;
    if (aEndDate < now) return 1;

    // If both are in the future, sort soonest close dates first.
    return aEndDate - bEndDate;
  });
};
