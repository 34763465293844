import React, { FunctionComponent } from "react";

import { AinsophVersion } from "@shared/contracts/wrappers/Ainsoph";
import { MarketplaceVersion } from "@shared/contracts/wrappers/Marketplace";

import { web3Provider } from "~/provider";
import { transactionToast } from "~/components/toasts/TransactionToast";

export interface ApproveTransfersProps {
  ainsophVersion: AinsophVersion;
  marketplaceVersion: MarketplaceVersion;
  message?: string;
}

export const ApproveTransfers: FunctionComponent<ApproveTransfersProps> = ({
  ainsophVersion,
  marketplaceVersion,
  message,
}) => {
  const handleApprove = async () => {
    const tx = await web3Provider.ainsoph?.setApproval(
      ainsophVersion,
      web3Provider.marketplace?.contracts[marketplaceVersion].address
    );
    transactionToast("Approving the illust marketplace contract", tx);
    return tx;
  };

  return (
    <>
      {message && (
        <div>
          {message}
          <br />
        </div>
      )}
      <button type="button" className="button--block" onClick={handleApprove}>
        Approve transfers
      </button>
    </>
  );
};
