import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";

import { useCurrentUser } from "~/account";
import { SEO } from "~/components/Routing/SEO";

import { CreateAccount } from "../CreateAccount/CreateAccount";
import { ProfileAssets } from "./ProfileAssets";
import { ProfileInfo } from "./ProfileInfo";

import "./UserProfile.css";

export const UserProfile: FunctionComponent<RouteComponentProps> = () => {
  const [, currentUser, profile] = useCurrentUser();

  if (!(currentUser && profile)) return null;

  // Show create account screen if user has not been initialized.
  if (!profile.email) {
    return <CreateAccount />;
  }

  return (
    <div className="user-profile">
      <SEO title="Profile" />
      <h1 className="user-profile__header">Account Info</h1>
      <div className="br user-profile__br" />
      <div className="h-position-relative h-flex wrap h-100pw h-drel">
        <div className="user-profile__sidebar">
          <ProfileInfo />
        </div>

        <div className="user-profile__content">
          <ProfileAssets />
        </div>
      </div>
    </div>
  );
};
