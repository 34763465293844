import { ethers } from "ethers";
import Web3Modal, { IProviderOptions } from "web3modal";
import Torus from "@toruslabs/torus-embed";
// import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import WalletConnectProvider from "@walletconnect/web3-provider";
import ethProvider from "eth-provider";
import MewConnect from "@myetherwallet/mewconnect-web-client";
import create from "zustand";
import { Web3Provider as EthersWeb3Provider } from "@ethersproject/providers";

import { Ainsoph } from "@shared/contracts/wrappers/Ainsoph";
import { Marketplace } from "@shared/contracts/wrappers/Marketplace";

import { getEnv } from "@shared/env";

import { account } from "./account";

/** Adds types that are on window.ethereum */
interface FullProvider extends ethers.providers.ExternalProvider {
  chainId?: string;
  enable: () => Promise<void>;
  autoRefreshOnNetworkChange: boolean;
  on: (event: string, watcher: (...args: any[]) => void) => void;
  once: (event: string, watcher: (...args: any[]) => void) => void;
  removeListener(event: string, watcher: (...args: any[]) => void): void;

  /** Injected by torus */
  isTorus?: boolean;
}

const providerOptions: IProviderOptions = {
  injected: { package: null },
  torus: {
    package: Torus,
    options: {},
  },
  // portis: {
  //   package: Portis,
  //   options: {
  //     id: getEnv('portisKey'),
  //   },
  // },
  fortmatic: {
    package: Fortmatic,
    options: {
      key: getEnv("fortmaticKey"),
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: getEnv("infuraId"),
    },
  },
  frame: {
    package: ethProvider,
  },
  mewconnect: {
    package: MewConnect,
    options: {
      infuraId: getEnv("infuraId"),
    },
  },
};

class Web3Provider {
  /** The underlying provider from web3Modal */
  private web3Provider: FullProvider;
  /** The connected provider */
  public provider?: EthersWeb3Provider;
  public selectedAddress: string | null = null;

  public ainsoph?: Ainsoph;
  public marketplace?: Marketplace;

  private onLogout = () => account.logout();

  private web3Modal = new Web3Modal({
    network: getEnv("ainsophNetwork"),
    theme: "dark",
    cacheProvider: true,
    providerOptions,
  });

  constructor() {
    // Solves a metamask warning.
    if (window.ethereum) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
    const baseProvider = ethers.providers.getDefaultProvider(
      getEnv("ainsophNetwork"),
      1
    ) as any;

    this.ainsoph = new Ainsoph(
      getEnv("ainsoph2Address"),
      getEnv("ainsoph3Address"),
      baseProvider
    );
    this.marketplace = new Marketplace(
      getEnv("marketplace2Address"),
      getEnv("marketplace3Address"),
      baseProvider
    );
  }

  useStore = create(() => ({
    selectedAddress: "",
  }));

  getProviderName(): string {
    if (!this.web3Provider) return "None";
    if (this.web3Provider.isMetaMask) return "MetaMask";
    if (this.web3Provider.isTorus) return "Torus";
    return this.provider.connection.url;
  }

  async connect(clearCache = true) {
    let cachedProvider: string;
    // Clear the provider cache (if requested).
    if (clearCache && this.web3Modal.cachedProvider) {
      cachedProvider = this.web3Modal.cachedProvider;
      this.web3Modal.clearCachedProvider();
    }

    if (this.web3Provider?.removeListener) {
      this.web3Provider.removeListener("close", this.onLogout);
      this.web3Provider.removeListener("accountsChanged", this.connectAddress);
      this.web3Provider.removeListener("chainChanged", this.onLogout);
      this.web3Provider.removeListener("networkChanged", this.onLogout);
    }

    try {
      this.web3Provider = (await this.web3Modal.connect()) as FullProvider;

      this.provider = new EthersWeb3Provider(this.web3Provider);

      // Listen for change events to log out/add an account.
      if (this.web3Provider.on) {
        this.web3Provider.on("close", this.onLogout);
        this.web3Provider.on("accountsChanged", this.connectAddress);
        this.web3Provider.on("chainChanged", this.onLogout);
        this.web3Provider.on("networkChanged", this.onLogout);
      }

      const selectedAddress = await this.getSigner().getAddress();
      this.setSelectedAddress(selectedAddress?.toLowerCase());

      // Connect to contracts with new provider
      this.ainsoph = new Ainsoph(
        getEnv("ainsoph2Address"),
        getEnv("ainsoph3Address"),
        this.provider
      );
      this.marketplace = new Marketplace(
        getEnv("marketplace2Address"),
        getEnv("marketplace3Address"),
        this.provider
      );
    } catch (error) {
      if (cachedProvider) {
        this.web3Modal.setCachedProvider(cachedProvider);
      }
      throw error;
    }
  }

  private connectAddress = async () => {
    try {
      const provider = await this.web3Modal.connectTo(
        this.web3Modal.cachedProvider
      );
      this.provider = new EthersWeb3Provider(provider);

      const selectedAddress = await this.getSigner().getAddress();
      this.setSelectedAddress(selectedAddress?.toLowerCase());

      await account.onAddressChange();
    } catch (error) {
      console.error(error);
    }
  };

  async reconnect() {
    if (!this.web3Modal.cachedProvider) return;
    try {
      await this.connect(false);
    } catch (error) {
      this.web3Modal.clearCachedProvider();
    }
  }

  logout(): void {
    this.provider = null;
    this.web3Modal.clearCachedProvider();
  }

  getSigner(): ethers.providers.JsonRpcSigner {
    if (this.provider && "getSigner" in this.provider) {
      return this.provider.getSigner();
    }

    throw new Error("You can't sign before connecting a wallet");
  }

  private setSelectedAddress(selectedAddress: string) {
    this.selectedAddress = selectedAddress;
    this.useStore.setState({ selectedAddress });
  }
}

export const web3Provider = new Web3Provider();
