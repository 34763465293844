import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import { assetUrl } from "@shared/assets";

export interface SEOProps {
  title?: string;
  image?: string;
}

export const SEO: FunctionComponent<SEOProps> = ({
  title,
  image = assetUrl("i-in-the-background.png"),
}) => {
  const finalTitle = title ? `Illust NFT | ${title}` : "Illust NFT";

  return (
    <Helmet>
      {title && <title>{finalTitle}</title>}
      {title && <meta property="og:title" content={finalTitle} />}
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
};
