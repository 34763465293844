import React, { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";

import { api } from "~/api";

export interface UsernameAddressProps {
  address: string;
}

/** Fetches a username from an address, with caching. */
export const UsernameAddress: FunctionComponent<UsernameAddressProps> = ({
  address,
}) => {
  const username = useAddressUsername(address);

  if (!username) {
    return <span>{address}</span>;
  }

  return <span title={address}>@{username}</span>;
};

/** Holds a dictionary of address => Promise */
const ownerPromises: Record<string, Promise<string>> = {};

/** Load a username from an address, but only once. */
const useAddressUsername = (address: string) => {
  const [username, setUsername] = useState("");

  useEffect(() => {
    // If this user has not been fetched, do so.
    if (!ownerPromises[address]) {
      ownerPromises[address] = axios
        .get<string>(`${api}/users/${address}/username`)
        .then((response) => response.data);
    }

    // Either way, get the user's name when ready.
    ownerPromises[address].then(setUsername);
  }, [address]);

  return username;
};
