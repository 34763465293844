import React, { FunctionComponent } from "react";
import ReactMapboxGl, {
  Marker,
  Popup,
  WebMercatorViewport,
  FlyToInterpolator,
} from "react-map-gl";
import axios from "axios";
import { ArtPiece } from "@shared/models/ArtPiece";
import { Link } from "@reach/router";
import "./MapCollection.css";
import { toast } from "react-toastify";
import { Pin } from "@shared/models/Pin";
import { getEnv } from "@shared/env";

import { web3Provider } from "~/provider";
import { api } from "~/api";
import { account } from "~/account";
import { Loading } from "~/components/Loading/Loading";

export interface CollectionItemsProps {
  artPieces: ArtPiece[];
  mapPieces: ArtPiece[];
}

export const MapCollection: FunctionComponent<CollectionItemsProps> = ({
  artPieces,
  mapPieces,
}) => {
  const EMPTY_PIN = {
    id: "",
    latitude: null,
    longitude: null,
    oldCoords: {
      longitude: null,
      latitude: null,
    },
  };

  const [popupInfo, setPopupInfo] = React.useState({
    artPiece: artPieces[0],
    index: null,
    visible: false,
  });

  const [canMovePiece, setMovePiece] = React.useState(false);
  const [viewport, setViewport] = React.useState({});
  const [activePin, setActivePin] = React.useState(EMPTY_PIN);

  if (!artPieces || !mapPieces) return <Loading />;

  const boundingBox: [[number, number], [number, number]] = [
    [
      Number(artPieces[0].coordinates.longitude),
      Number(artPieces[0].coordinates.latitude),
    ], //sw
    [
      Number(artPieces[0].coordinates.longitude),
      Number(artPieces[0].coordinates.latitude),
    ], //ne
  ];

  // find the smallest and largest lng & lat to set the bounding box
  artPieces.forEach((artPiece) => {
    const artLng = Number(artPiece.coordinates.longitude);
    const artLat = Number(artPiece.coordinates.latitude);

    // find smallest longitude
    if (artLng < boundingBox[0][0]) boundingBox[0][0] = artLng;

    // find smallest latitude
    if (artLat < boundingBox[0][1]) boundingBox[0][1] = artLat;

    // find largest longitude
    if (artLng > boundingBox[1][0]) boundingBox[1][0] = artLng;

    // find largest longitude
    if (artLat > boundingBox[1][1]) boundingBox[1][1] = artLat;
  });

  const handleMapLoad = () => {
    const newBounds = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(boundingBox, {
      padding: 20,
      maxZoom: 12,
    });
    setViewport({
      ...newBounds,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  // show the popup when pin is clicked only when not moving pieces
  const handlePopup = (
    artPiece: ArtPiece,
    popUpIndex: number,
    isOwner: boolean
  ) => {
    if (!activePin.id || artPiece.id === activePin.id) {
      if (artPiece.isOpenEdition) {
        setMovePiece(false);
      } else {
        setMovePiece(isOwner);
      }
      setPopupInfo({ artPiece, index: popUpIndex, visible: true });
    }
  };

  const onMarkerDragEnd = (event) => {
    setActivePin({
      ...activePin,
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
  };

  const handleMove = async () => {
    const tokenId = getAttributeValueByArtId(activePin.id, "tokenId");
    await sendMove(tokenId, activePin.longitude, activePin.latitude);
  };

  const resetMarker = () => {
    activePin.latitude = activePin.oldCoords.latitude;
    activePin.longitude = activePin.oldCoords.longitude;
    setActivePin(EMPTY_PIN);
  };

  const getAttributeValueByArtId = (id, attribute) => {
    let returnAttributeValue = null;
    artPieces.forEach((item) => {
      if (item.id === id) {
        returnAttributeValue = item[attribute];
      }
    });
    return returnAttributeValue;
  };
  const sendMove = async (
    tokenId: string,
    long: string,
    lat: string
  ): Promise<void> => {
    if (!web3Provider.selectedAddress) {
      toast.error("Your do not have permission to move this art piece");
      return;
    }

    const message = {
      tokenId,
      long,
      lat,
      timestamp: Date.now(),
    };
    const body = {
      message,
      sig: await account.sign(JSON.stringify(message)),
    };

    try {
      const response = await axios.post(`${api}/market/move`, body);
      toast.dark(response.data);
    } catch (error) {
      toast.error(`Can't move piece`);
    }
    setActivePin(EMPTY_PIN);
    setPopupInfo({
      ...popupInfo,
      visible: false,
      index: null,
    });
  };
  return (
    <div className="mapCollection__wrapper">
      <ReactMapboxGl
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        onLoad={() => handleMapLoad()}
        mapStyle={getEnv("mapboxStyleUrl")}
        mapboxApiAccessToken={getEnv("mapboxApiKey")}
      >
        {mapPieces.map((artPiece, index) => (
          <div key={artPiece.slug}>
            <Marker
              latitude={Number(artPiece.coordinates.latitude)}
              longitude={Number(artPiece.coordinates.longitude)}
              offsetTop={-40}
              offsetLeft={-13}
              draggable={false}
            >
              <Pin
                popupIndex={index}
                isOwner
                onChildClick={() => handlePopup(artPiece, index, false)}
                fillColor="#242424"
                artPiece={artPiece}
              />
            </Marker>
          </div>
        ))}
        {artPieces.map((artPiece, index) => (
          <div key={artPiece.slug}>
            <Marker
              draggable={activePin.id === artPiece.id}
              onDragEnd={(event) => onMarkerDragEnd(event)}
              latitude={
                activePin.id === artPiece.id
                  ? activePin.latitude
                  : Number(artPiece.coordinates.latitude)
              }
              longitude={
                activePin.id === artPiece.id
                  ? activePin.longitude
                  : Number(artPiece.coordinates.longitude)
              }
              offsetTop={-40}
              offsetLeft={-13}
            >
              <Pin
                popupIndex={index}
                isOwner={false}
                onChildClick={() => handlePopup(artPiece, index, true)}
                fillColor={activePin.id === artPiece.id ? "#cfab72" : "#a47a44"}
                artPiece={artPiece}
              />
            </Marker>
          </div>
        ))}
        {popupInfo.artPiece && popupInfo.visible && (
          <Popup
            latitude={
              activePin.id === popupInfo.artPiece.id
                ? activePin.latitude
                : Number(popupInfo.artPiece.coordinates.latitude)
            }
            longitude={
              activePin.id === popupInfo.artPiece.id
                ? activePin.longitude
                : Number(popupInfo.artPiece.coordinates.longitude)
            }
            closeButton
            offsetTop={-40}
            closeOnClick={false}
            onClose={() =>
              setPopupInfo({
                ...popupInfo,
                index: null,
                visible: false,
              })
            }
            anchor="bottom"
          >
            <div className="popup__title">{popupInfo.artPiece.name}</div>
            <Link
              to={`/nft-art/${popupInfo.artPiece.artistSlug}/${popupInfo.artPiece.slug}`}
            >
              View Piece
            </Link>
            <div>
              {!activePin.id && canMovePiece && (
                <button
                  type="button"
                  id={popupInfo.artPiece.id}
                  onClick={() => {
                    setActivePin({
                      ...activePin,
                      id: popupInfo.artPiece.id,
                      latitude: popupInfo.artPiece.coordinates.latitude,
                      longitude: popupInfo.artPiece.coordinates.longitude,
                      oldCoords: {
                        ...getAttributeValueByArtId(
                          popupInfo.artPiece.id,
                          "coordinates"
                        ),
                      },
                    });
                  }}
                >
                  Move Peice
                </button>
              )}

              {activePin.id && (
                <button
                  type="button"
                  id={popupInfo.artPiece.id}
                  onClick={() => {
                    resetMarker();
                  }}
                >
                  Reset
                </button>
              )}
            </div>
          </Popup>
        )}
      </ReactMapboxGl>
      {activePin.id && (
        <div className="mapCollection__newLatLong">
          <span>Lat: {activePin.latitude}</span> |{" "}
          <span>Long: {activePin.longitude}</span>
          <button
            className="button--block mapCollection__saveButton"
            type="button"
            onClick={handleMove}
          >
            save
          </button>
        </div>
      )}
    </div>
  );
};
