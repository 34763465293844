import { useState, useEffect } from "react";

import firebase from "firebase/compat/app";

import { Artist } from "@shared/models/Artist";
import { ArtPiece } from "@shared/models/ArtPiece";
import { Bid } from "@shared/models/Bid";

import { db } from "~/lib/firebase";

import { getErrorMessage } from "../error";

type CollectionReference<T> = firebase.firestore.CollectionReference<T>;

export const artPieceCollection = db.collection(
  "art-pieces"
) as CollectionReference<ArtPiece>;

export const artistCollection = db.collection(
  "artists"
) as CollectionReference<Artist>;

export const bidCollection = db.collection("bids") as CollectionReference<Bid>;

export const useWatchArtPiece = (
  assetSlug: string,
  by = "slug"
): [boolean, ArtPiece | null, string] => {
  const [artPieceLoaded, setArtPieceLoaded] = useState(false);
  const [artPieceError, setArtPieceError] = useState("");
  const [artPiece, setArtPiece] = useState<ArtPiece | null>(null);

  useEffect(() => {
    if (!assetSlug) return undefined;

    return artPieceCollection.where(by, "==", assetSlug).onSnapshot(
      (query) => {
        const doc = query.docs[0];
        if (!doc) {
          setArtPiece(null);
        } else {
          setArtPiece({ ...doc.data(), id: doc.id });
        }

        setArtPieceLoaded(true);
      },
      (error) => {
        setArtPieceError(getErrorMessage(error));
      }
    );
  }, [assetSlug, by]);

  return [artPieceLoaded, artPiece, artPieceError];
};

export const useWatchArtPieces = (): [boolean, Record<string, ArtPiece>] => {
  const [loaded, setLoaded] = useState(false);
  const [artPieces, setArtPieces] = useState<Record<string, ArtPiece>>({});

  useEffect(() => {
    return artPieceCollection
      .where("showInMarketplace", "==", true)
      .onSnapshot((query) => {
        const loadedArtPieces: Record<string, ArtPiece> = {};
        query.forEach((doc) => {
          const artPiece = doc.data();
          loadedArtPieces[doc.id] = { ...artPiece, id: doc.id };
        });

        setArtPieces(loadedArtPieces);
        setLoaded(true);
      });
  }, [setArtPieces, setLoaded]);

  return [loaded, artPieces];
};

export const useWatchBids = (tokenId: string): [boolean, Bid[]] => {
  const [loaded, setLoaded] = useState(false);
  const [bids, setBids] = useState<Bid[]>([]);

  useEffect(() => {
    if (!tokenId) {
      setLoaded(true);
      return undefined;
    }

    return bidCollection
      .where("asset", "==", tokenId)
      .orderBy("timestamp", "desc")
      .onSnapshot((query) => {
        const docs: Bid[] = [];
        query.forEach((doc) => {
          docs.push(doc.data());
        });
        setBids(docs);
        setLoaded(true);
      });
  }, [tokenId, setBids, setLoaded]);

  return [loaded, bids];
};

export const useLoadArtists = (): [boolean, Record<string, Artist>] => {
  const [artistsLoaded, setArtistsLoaded] = useState(false);
  const [artists, setArtists] = useState<Record<string, Artist>>({});
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [artistsWithAssets, setArtistsWithAssets] = useState<string[]>([]);

  useEffect(() => {
    return artPieceCollection.onSnapshot(
      (snapshot) => {
        const artistIdSet = new Set<string>();

        snapshot.forEach((doc) => {
          const artPiece = doc.data() as ArtPiece;

          if (artPiece.showInMarketplace && artPiece.artistId) {
            artistIdSet.add(artPiece.artistId);
          }
        });

        setAssetsLoaded(true);
        setArtistsWithAssets(Array.from(artistIdSet));
      },
      () => {
        setAssetsLoaded(true);
      }
    );
  }, [setAssetsLoaded, setArtistsWithAssets]);

  useEffect(() => {
    return artistCollection.onSnapshot(
      (snapshot) => {
        const artistData: Record<string, Artist> = {};

        snapshot.forEach((doc) => {
          artistData[doc.id] = { ...doc.data(), id: doc.id } as Artist;
        });
        setArtists(artistData);
        setArtistsLoaded(true);
      },
      () => {
        setArtistsLoaded(true);
      }
    );
  }, [setArtistsLoaded, setArtists]);

  if (!(artistsLoaded && assetsLoaded)) {
    return [false, {}];
  }

  // Filter out artists without marketplace assets.
  const filteredArtists: Record<string, Artist> = {};
  artistsWithAssets.forEach((artistId) => {
    const artist = artists[artistId];
    if (artist) {
      filteredArtists[artistId] = artist;
    }
  });

  return [true, filteredArtists];
};

export const useLoadArtist = (artistSlug: string): [boolean, Artist | null] => {
  const [artistLoaded, setArtistLoaded] = useState(false);
  const [artist, setArtist] = useState<Artist | null>(null);

  useEffect(() => {
    return artistCollection.where("slug", "==", artistSlug).onSnapshot(
      (snapshot) => {
        const doc = snapshot.docs[0];
        if (doc) {
          const artistData = doc?.data();
          setArtist({ ...artistData, id: doc.id });
        } else {
          setArtist(null);
        }
        setArtistLoaded(true);
      },
      () => {
        setArtistLoaded(true);
      }
    );
  }, [setArtist, setArtistLoaded, artistSlug]);

  return [artistLoaded, artist];
};
