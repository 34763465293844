import { ArtPiece } from "@shared/models/ArtPiece";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getEnv } from "@shared/env";

export type CollectionReference<T> = firebase.firestore.CollectionReference<T>;

const config = {
  apiKey: getEnv("firebaseApiKey"),
  authDomain: getEnv("firebaseAuthDomain"),
  projectId: getEnv("firebaseProjectId"),
  storageBucket: getEnv("firebaseStorageBucket"),
  messagingSenderId: getEnv("firebaseMessagingSenderId"),
  appId: getEnv("firebaseAppId"),
};

export const app = firebase.initializeApp(config);

export const db = app.firestore();
export const auth = app.auth();

export const artPieceCollection = db.collection(
  "art-pieces"
) as CollectionReference<ArtPiece>;
