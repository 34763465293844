import React, { FunctionComponent } from "react";

import { Bid } from "@shared/models/Bid";

import { UsernameAddress } from "~/components/UsernameAddress";

export interface BidHistoryProps {
  bids: Bid[];
}

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export const BidHistory: FunctionComponent<BidHistoryProps> = ({ bids }) => {
  return (
    <div id="bidHistory">
      <div className="auction__history">
        {bids.map((bid) => {
          return (
            <div key={bid.timestamp} className="auction__bidder">
              {formatTime(bid.timestamp)} ago:
              <br />
              <UsernameAddress address={bid.bidder} />
              &nbsp;
              {bid.amount}
              &nbsp;ETH
            </div>
          );
        })}
        <div className="auction__overlay" />
      </div>
    </div>
  );
};

const formatTime = (timestamp: number): string => {
  const timeLeft = Date.now() - new Date(timestamp).getTime();

  return `
    ${
      Math.floor(timeLeft / ONE_DAY)
        ? `${Math.floor(timeLeft / ONE_DAY)} Days`
        : ""
    }
    ${
      Math.floor((timeLeft % ONE_DAY) / ONE_HOUR)
        ? `${Math.floor((timeLeft % ONE_DAY) / ONE_HOUR)} Hours`
        : ""
    }
    ${
      Math.floor((timeLeft % ONE_HOUR) / ONE_MINUTE)
        ? `${Math.floor((timeLeft % ONE_HOUR) / ONE_MINUTE)} Minutes`
        : ""
    }
    ${
      Math.floor((timeLeft % ONE_MINUTE) / ONE_SECOND)
        ? `${Math.floor((timeLeft % ONE_MINUTE) / ONE_SECOND)} Seconds`
        : ""
    }
  `;
};
