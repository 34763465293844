import { BigNumberish, ContractTransaction, ethers } from "ethers";
import type { Web3Provider } from "@ethersproject/providers";

import {
  IllustCustody,
  IllustCustody__factory,
  IllustMarketplace3,
  IllustMarketplace3__factory,
} from "../abi";
import { RoyaltySplit } from "./RoyaltySplit";

interface Contracts {
  v2: IllustCustody;
  v3: IllustMarketplace3;
}

export type MarketplaceVersion = keyof Contracts;

/** Wrapper for interaction with the v2 CustodyContract and the v3 Marketplace Contract */
export class Marketplace {
  contracts: Contracts;

  constructor(
    v2Address: string,
    v3Address: string,
    private provider: Web3Provider
  ) {
    this.contracts = {
      v2: IllustCustody__factory.connect(v2Address, this.provider),
      v3: IllustMarketplace3__factory.connect(v3Address, this.provider),
    };
  }

  acceptBid(
    version: MarketplaceVersion,
    tokenId: string,
    price: string,
    winnerAddress: string
  ): Promise<ContractTransaction> {
    if (version === "v2") {
      const contract = this.contracts[version].connect(
        this.provider.getSigner()
      );

      return contract.listAsset(
        tokenId,
        ethers.utils.parseEther(price),
        winnerAddress
      );
    }

    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.acceptBid(
      tokenId,
      ethers.utils.parseEther(price),
      winnerAddress
    );
  }

  setRoyalties(
    version: MarketplaceVersion,
    tokenId: string,
    marketplaceFeePercentage: number,
    primaryRoyaltySplits: RoyaltySplit[]
  ): Promise<ContractTransaction> {
    if (version === "v2") {
      return Promise.reject(new Error("not supported in v2"));
    }

    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.setRoyalties(
      tokenId,
      marketplaceFeePercentage,
      primaryRoyaltySplits || [],
      {
        gasLimit: 150_000,
      }
    );
  }

  pay(version: MarketplaceVersion, tokenId: string, price: string) {
    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.pay(tokenId, {
      value: ethers.utils.parseEther(price),
      gasLimit: 400_000,
    });
  }

  winningBids(version: MarketplaceVersion, tokenId: string) {
    if (version === "v2") {
      const contract = this.contracts[version];

      return contract.winnigBids(tokenId);
    }

    const contract = this.contracts[version];

    return contract.winningBids(tokenId);
  }

  mintOpenEdition(
    version: MarketplaceVersion,
    tokenId: string,
    price: BigNumberish
  ) {
    if (version === "v2") {
      throw new Error("not supported in v2");
    }

    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.mintOpenEdition(tokenId, {
      value: price,
      gasLimit: 400_000,
    });
  }

  createOpenEdition(
    version: MarketplaceVersion,
    tokenId: string,
    artistAddress: string,
    price: string,
    maxEditions: number,
    primaryRoyaltySplits: RoyaltySplit[],
    secondaryRoyaltySplits: RoyaltySplit[]
  ) {
    if (version === "v2") {
      throw new Error("not supported in v2");
    }

    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.createOpenEdition(
      tokenId,
      artistAddress,
      ethers.utils.parseEther(price),
      maxEditions,
      primaryRoyaltySplits || [],
      secondaryRoyaltySplits || []
    );
  }
}
