import React, { FunctionComponent } from "react";
import ReactMapboxGl, { Marker } from "react-map-gl";
import { ArtPiece } from "@shared/models/ArtPiece";

import { getEnv } from "@shared/env";

export interface AssetMapProps {
  artPiece: ArtPiece;
}

export const AssetMap: FunctionComponent<AssetMapProps> = ({ artPiece }) => {
  const [viewport, setViewport] = React.useState({
    latitude: artPiece.coordinates.latitude as number,
    longitude: artPiece.coordinates.longitude as number,
    zoom: 8,
  });
  return (
    <ReactMapboxGl
      {...viewport}
      onViewportChange={setViewport}
      width="100%"
      height="450px"
      mapStyle={getEnv("mapboxStyleUrl")}
      mapboxApiAccessToken={getEnv("mapboxApiKey")}
    >
      <Marker
        latitude={artPiece.coordinates.latitude as number}
        longitude={artPiece.coordinates.longitude as number}
        offsetTop={-40}
        offsetLeft={-13}
      >
        {/* eslint-disable-next-line global-require */}
        <img src={require("~/assets/images/pin.svg")} alt="pin" />
      </Marker>
    </ReactMapboxGl>
  );
};
