import { toast } from "react-toastify";

export const copyText = async (
  textToCopy: string,
  message?: string
): Promise<void> => {
  await navigator.clipboard.writeText(textToCopy);

  toast.dark(message || "Copied text");
};
