import React, { FunctionComponent, ReactNode } from "react";
import { Link } from "@reach/router";

import { ArtPiece } from "@shared/models/ArtPiece";
import { assetUrl } from "@shared/assets";

import { copyText } from "~/lib/copyText";
import { artistPath, artPieceTokenPath } from "~/lib/routes";
import { SEO } from "~/components/Routing/SEO";

import { ModelPreview } from "./ModelPreview";

import "./MarketplaceAsset.css";

export interface LotPageDetailsProps {
  artPiece: ArtPiece;
  /** Related to the name of the piece */
  title: string;
  /** Message for social media */
  socialMessage: string;
  /** Specify the tokenId, because it can be different for open editions. */
  tokenId?: string;
  /** Auction details */
  children: ReactNode;
}

/** Shared details for asset lot pages. */
export const LotPageDetails: FunctionComponent<LotPageDetailsProps> = ({
  title,
  artPiece,
  tokenId,
  socialMessage,
  children,
}) => {
  const socialLink = encodeURIComponent(window.location.href);

  const etherscanLink = tokenId
    ? artPieceTokenPath(artPiece.ainsophVersion, tokenId)
    : "";

  return (
    <>
      <SEO title={title} image={artPiece.poster} />

      <div id="lotBox" className="lotAsset">
        <h1 className="lotAsset__title">{title}</h1>

        <div className="lotAsset__wrapper">
          <div className="lotAsset__content">
            <div className="lotAsset__viewer">
              <ModelPreview artPiece={artPiece} showButtons />
            </div>
            <div className="lotAsset__details">
              <h2 className="lotAsset__name">
                <Link to={artistPath(artPiece.artistSlug)}>
                  {artPiece.artist || "Illust"}
                </Link>
              </h2>

              <div className="lotAsset__attribute">
                Created By:{" "}
                <Link to={artistPath(artPiece.artistSlug)}>
                  {artPiece.artist || "Illust"}
                </Link>
              </div>
              <div className="lotAsset__attribute">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: artPiece.about || "",
                  }}
                />
              </div>
              {etherscanLink && (
                <div className="lotAsset__attribute">
                  History:{" "}
                  <a target="_blank" rel="noreferrer" href={etherscanLink}>
                    Etherscan
                  </a>
                </div>
              )}
              <div className="lotAsset__shareWrapper">
                <button
                  className="share__link"
                  type="button"
                  onClick={() => copyText(window.location.href, "Copied link")}
                >
                  Share Link
                </button>
                <button
                  className="share__link"
                  type="button"
                  onClick={() =>
                    window.open(
                      `https://twitter.com/intent/tweet?text=${socialMessage}: ${socialLink}`,
                      "_blank"
                    )
                  }
                >
                  <img
                    className="share__twitterIcon"
                    src={assetUrl("icons/twitter.svg")}
                    alt=""
                  />
                  Tweet
                </button>
                <iframe
                  title="facebook"
                  className="share__facebookButton"
                  src={`https://www.facebook.com/plugins/share_button.php?href=${socialLink}&layout=button_count&size=large&appId=794158374066212&width=110&height=28`}
                  width="110"
                  height="28"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
              </div>
            </div>

            <div className="lotAsset__auction">{children}</div>
          </div>
        </div>
        <div>
          {artPiece.footer && (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: artPiece.footer,
              }}
            />
          )}

          {artPiece.footerImage ? (
            <img alt="" src={artPiece.footerImage} className="footerImg" />
          ) : null}
        </div>
      </div>
    </>
  );
};
