import React, { FunctionComponent, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

import "react-toastify/dist/ReactToastify.css";

import { getEnv } from "@shared/env";
import { account } from "~/account";
import { Footer } from "./Routing/Footer";
import { Header } from "./Routing/Header/Header";
import { Routes } from "./Routing/Routes";
import { Sidebar } from "./Routing/Sidebar/Sidebar";
import { Intercom } from "./Routing/Intercom";

export const App: FunctionComponent = () => {
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    account.reconnect().then(() => setConnected(true));
  }, []);

  if (!connected) return null;

  return (
    <>
      <Header />
      <Sidebar />

      <ToastContainer position="bottom-left" />
      <div id="content">
        <Routes />
      </div>
      <Footer />
      <IntercomProvider appId={getEnv("intercomKey")}>
        <Intercom />
      </IntercomProvider>
    </>
  );
};
