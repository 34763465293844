import { ArtPiece } from "@shared/models/ArtPiece";
import React, { Fragment, FunctionComponent, useState } from "react";

import { CollectionItem } from "./CollectionItem";
import { MarketHeader } from "./MarketHeader";

export interface CollectionItemGroupProps {
  title: string;
  banner?: string;
  bannerAlt?: string;
  artPieces: ArtPiece[];
  isFirst: boolean;
}

const MAX_LISTING_SIZE = 6;

export const CollectionItemGroup: FunctionComponent<
  CollectionItemGroupProps
> = ({ title, banner, bannerAlt, artPieces, isFirst }) => {
  const [isOpen, setIsOpen] = useState(false);

  const firstAssetSet = artPieces.slice(0, MAX_LISTING_SIZE);
  const seeMoreButton = firstAssetSet.length !== artPieces.length;
  const filteredAssets = isOpen ? artPieces : firstAssetSet;

  return (
    <Fragment key={title}>
      <MarketHeader
        title={title}
        banner={banner}
        bannerAlt={bannerAlt}
        topHeader={isFirst ? "Market" : ""}
      />

      <div className="market__collection">
        {filteredAssets.map((artPiece) => (
          <CollectionItem key={artPiece.id} artPiece={artPiece} />
        ))}
      </div>

      {seeMoreButton && (
        <button
          type="button"
          className="button--block"
          onClick={() => setIsOpen(true)}
        >
          See More
        </button>
      )}
    </Fragment>
  );
};
