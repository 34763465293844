import { string, object, InferType } from "yup";

export const ENTRANCE_CODE_COLLECTION_NAME = "vip-codes";

/** VIP Entrance Code for publishers */
export interface EntranceCode {
  id: string;
  twitter: string;
  email: string;
  code: string;
  username: string;
  bonusExperiences: BonusExperience[];
  availableRedemptions: number;
  usedRedemptions: number;
  expirationTime: number;
  mapMarkerIconPath: string;
}

export const bonusExperienceSchema = object({
  id: string().default(""),
  title: string().default(""),
  url: string().url().default(""),
});

export interface BonusExperience
  extends InferType<typeof bonusExperienceSchema> {}

export const emptyBonusExperience = bonusExperienceSchema.getDefault();

export const emptyEntranceCode: EntranceCode = {
  id: "",
  email: "",
  code: "",
  username: "",
  twitter: "",
  bonusExperiences: [],
  availableRedemptions: 1,
  usedRedemptions: 0,
  expirationTime: 0,
  mapMarkerIconPath: "",
};

/** Return an error message if the code is invalid. */
export const validateCode = (code: EntranceCode | null) => {
  if (!code) {
    return "Code not found";
  }

  if (
    code.availableRedemptions &&
    Number(code.usedRedemptions) >= code.availableRedemptions
  ) {
    return code.availableRedemptions === 1
      ? "This code has been redeemed. Check with @illustspace on twitter for more codes."
      : `All ${code.availableRedemptions} codes have been redeemed. Check with @illustspace on twitter for more codes.`;
  }

  if (code.expirationTime && code.expirationTime < Date.now()) {
    return "This code is expired. Check with @illustspace on twitter for more codes.";
  }

  return null;
};
