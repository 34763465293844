import React, { FunctionComponent } from "react";
import { Link } from "@reach/router";
import { MarketNav } from "./MarketNav";

export interface MarketHeaderProps {
  topHeader?: string;
  title?: string;
  banner?: string;
  bannerAlt?: string;
}

export const MarketHeader: FunctionComponent<MarketHeaderProps> = ({
  title,
  banner,
  bannerAlt,
  topHeader = true,
}) => {
  return (
    <>
      {topHeader && (
        <h1 className="market__heading">
          <Link className="link--text" to="/">
            {topHeader}
          </Link>
        </h1>
      )}

      <div className="market__filter-heading-wrapper">
        {topHeader && <MarketNav />}
        {title && <h2 className="market__filter-heading">{title}</h2>}
      </div>

      <div className="market__banner">
        {banner && (
          <img
            className="market__bannerImage"
            alt={bannerAlt || ""}
            src={banner}
          />
        )}
      </div>
    </>
  );
};
