import React, { FunctionComponent } from "react";
import { Link } from "@reach/router";

import "./LoginLink.css";

export const LoginLink: FunctionComponent = ({ children }) => (
  <Link className="login-link" to="/login">
    {children}
  </Link>
);
