export type ChainName =
  | "mainnet"
  | "ropsten"
  | "rinkeby"
  | "goerli"
  | "kovan"
  | null;

export const getChainName = (chainId: string | number): ChainName => {
  if (!chainId) return "mainnet";

  switch (Number(chainId)) {
    case 1:
      return "mainnet";
    case 3:
      return "ropsten";
    case 4:
      return "goerli";
    case 5:
      return "kovan";
    case 42:
      return "kovan";
    default:
      return "mainnet";
  }
};

/** Get the name of the current network of the web3 wallet. */
export const getCurrentNetworkName = (): string => {
  const chain = getChainName(window.ethereum?.chainId);
  switch (chain) {
    case "mainnet":
      return "Main Ethereum Network";
    case "ropsten":
      return "Ropsten Ethereum Test Network";
    case "rinkeby":
      return "Rinkeby Ethereum Test Network";
    case "goerli":
      return "Goerli Ethereum Test Network";
    case "kovan":
      return "Kovan Ethereum Network";
    default:
      return "No provider connected";
  }
};

export const getEtherscanNetworkLink = (
  chainId: string | number,
  path: string
): string => {
  const chainName = getChainName(chainId);

  let host = "";

  if (chainName === "mainnet") {
    host = "https://etherscan.io";
  } else if (chainName) {
    host = `https://${chainName}.etherscan.io`;
  } else {
    throw new Error("Unknown chain");
  }

  return [host, path].join("/");
};
