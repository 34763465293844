import React, { FunctionComponent } from "react";
import { Link } from "@reach/router";

import { ArtPiece } from "@shared/models/ArtPiece";

import { assetPath } from "~/lib/routes";
import { getTimeLeftMessage, useTimeLeft } from "~/lib/timeRemaining";

import { ModelPreview } from "../Asset/ModelPreview";
import { TimerVisual } from "~/lib/TimerVisual";

import "./CollectionItem.css";

export interface CollectionItemProps {
  artPiece: ArtPiece;
}

/** A small card that displays an asset. */
export const CollectionItem: FunctionComponent<CollectionItemProps> = ({
  artPiece,
}) => {
  const timeRemaining = useTimeLeft(artPiece.activeAuction?.endDate);

  const displayPrice = getDisplayPrice(artPiece, timeRemaining);

  const assetName = artPiece.name || "Untitled";
  const assetCreator = artPiece.artist || "Illust";
  const assetEdition = `${artPiece.edition}`;

  return (
    <div className="market__collectionItem">
      <div className="collectionItem__wrapper">
        <div className="collectionItem__auctionDetails">
          <div className="collectionItem__price">{displayPrice || ""}</div>
          <div className="collectionItem__time">
            {getTimeLeftMessage(timeRemaining) || ""}
          </div>
          <TimerVisual timeRemaining={timeRemaining} />
        </div>
        <ModelPreview artPiece={artPiece} />
        <Link
          className="collectionItem__attributes link--text"
          to={assetPath(artPiece)}
        >
          <h3 className="collectionItem__title">{assetName}</h3>
          <div className="collectionItem__artist">
            Creator: <strong>{assetCreator}</strong>
          </div>
          {assetEdition && (
            <div className="collectionItem__edition">
              Edition: <strong>{assetEdition}</strong>
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};

const getDisplayPrice = (artPiece: ArtPiece, timeRemaining: number) => {
  if (!(artPiece && timeRemaining > 0)) return "";

  if (artPiece.isOpenEdition && artPiece.price) {
    return ` Buy: ${artPiece.price} ETH`;
  }

  if (artPiece.price && artPiece.price > artPiece.activeAuction.startPrice) {
    return ` Bid: ${artPiece.price} ETH`;
  }

  if (artPiece.activeAuction?.startPrice) {
    return ` Start Price: ${artPiece.activeAuction.startPrice} ETH`;
  }

  return "";
};
