import React, { FunctionComponent, useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { SEO } from "~/components/Routing/SEO";

import { account, useCurrentUser } from "~/account";

import "./Connect.css";

export const Connect: FunctionComponent<RouteComponentProps> = () => {
  const handleWeb3 = async () => {
    await account.login();
    window.gtag("event", "login");
  };

  const [isLoading, currentUser] = useCurrentUser();

  useEffect(() => {
    if (!isLoading && currentUser) {
      navigate("/account");
    }
  }, [isLoading, currentUser]);

  return (
    <div className="connect">
      <SEO title="Login" />

      <h1 style={{ margin: "64px 1% 0" }}>Connect</h1>
      <div
        className="br"
        id="connectionMethod"
        style={{
          width: "64px",
          float: "left",
          margin: "64px calc(98% - 64px) 64px 1%",
        }}
      />

      <p style={{ fontSize: "12px" }}>
        Illust Space, a web3.0 application, uses a crypto wallet for buying,
        selling, and trading augmented reality art. Your account is tied to your
        wallet. Please connect with a Web3.0 Provider like Metamask or Torus
        below. Torus is an easy to use service for creating your first crypto
        wallet using an existing email, facebook account, or other online
        authentication services. Or you can connect to another provider if you
        already have a provider on your browser such as MetaMask or Coinbase
        Wallet; Illust Space does not have access to your private keys, please
        keep your private key information to yourself as this is highly
        sensitive information.
      </p>

      <p style={{ fontSize: "12px" }}>
        {window.ethereum
          ? "Web3 Browser Detected."
          : "No Web3 Browser Detected."}
      </p>

      <button type="button" className="button--block" onClick={handleWeb3}>
        Connect Web3 Provider
      </button>
      <br />
      <br />
    </div>
  );
};
