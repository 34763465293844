import React, { FunctionComponent, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import { ArtPiece } from "@shared/models/ArtPiece";
import { Bid } from "@shared/models/Bid";

import { web3Provider } from "~/provider";
import { getTimeLeftMessage } from "~/lib/timeRemaining";

import { BidHistory } from "./BidHistory";
import { auth } from "~/lib/firebase";
import { api } from "~/api";
import { account } from "~/account";
import { TimerVisual } from "~/lib/TimerVisual";

declare global {
  interface Window {
    gtag: any;
  }
}
window.gtag = window.gtag || {};

export interface AuctionDetailsProps {
  metadata: ArtPiece;
  bids: Bid[];
  owner: string;
  timeLeft: number;
}

export const ActiveAuctionDetails: FunctionComponent<AuctionDetailsProps> = ({
  metadata,
  bids,
  owner,
  timeLeft,
}) => {
  const { activeAuction } = metadata;

  const timeLeftMessage = getTimeLeftMessage(timeLeft);
  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );

  const handleBid = async () => {
    await sendBid(metadata.tokenId || metadata.id, bidAmount);
    window.gtag("event", "bid", {
      value: bidAmount,
    });
  };

  const latestPrice = bids.length ? bids[0]?.amount : metadata.price;

  const [bidAmount, setBidAmount] = useState(() => {
    return ethers.utils.formatEther(
      ethers.utils.parseEther(latestPrice).add(ethers.utils.parseEther("0.2"))
    );
  });

  return (
    <>
      <div className="auction__label">Time Remaining</div>
      <div className="auction__attribute" id="countdownBox">
        {timeLeftMessage}
        <TimerVisual timeRemaining={timeLeft} />
      </div>

      <div className="auction__label">
        {metadata.price === activeAuction.startPrice
          ? "Start price"
          : "Current Bid"}
      </div>
      <div className="auction__attribute" id="priceBox">
        {metadata.price} ETH
      </div>

      {metadata.price !== activeAuction.startPrice && (
        <>
          <div className="auction__label">Starting Price</div>

          <div className="auction__attribute">
            {activeAuction?.startPrice} ETH
          </div>
        </>
      )}
      {/* {!!activeAuction?.reservePrice && (
        <>
          <div className="auction__label">Reserve Price</div>
          <div className="auction__attribute">
            {activeAuction?.reservePrice} ETH
          </div>
        </>
      )} */}
      {/* check user is not owner */}
      {owner !== selectedAddress && (
        <>
          <label className="auction__label" htmlFor="js-bidAmount">
            Place Bid
          </label>
          <span>ETH</span>
          <input
            style={{ margin: 0 }}
            type="number"
            step="0.2"
            value={bidAmount}
            onChange={(event) => setBidAmount(event.target.value)}
          />
          <button
            type="button"
            className="button auction__bidButton"
            onClick={handleBid}
          >
            Place Bid
          </button>
        </>
      )}
      <BidHistory bids={bids} />
    </>
  );
};

export const sendBid = async (
  tokenId: string,
  amount: string
): Promise<void> => {
  if (!web3Provider.selectedAddress) {
    toast.error(
      "Please login or create an account by clicking account button in the top left corner to submit a bid"
    );
    return;
  }

  const message = {
    tokenId,
    amount,
    timestamp: Date.now(),
  };

  const idToken = await auth.currentUser.getIdToken();

  await axios.post(
    `${api}/market/bid`,
    {
      message,
      sig: await account.sign(JSON.stringify(message)),
    },
    {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }
  );
};
