import { ArtPiece } from "@shared/models/ArtPiece";
import { AinsophVersion } from "@shared/contracts/wrappers/Ainsoph";
import { MarketplaceVersion } from "@shared/contracts/wrappers/Marketplace";
import { ethers } from "ethers";
import { useCallback } from "react";
import { useAsync } from "~/lib/useAsync";
import { web3Provider } from "~/provider";

export interface AuctionStatus {
  isApprovedWinner: boolean;
  isAuctionComplete: boolean;
}

const closedAuction: AuctionStatus = {
  isApprovedWinner: false,
  isAuctionComplete: true,
};

export const useAuctionStatus = (
  artPiece: ArtPiece,
  winnerAddress: string,
  winningPrice: string
): AuctionStatus | null => {
  const [response] = useAsync<AuctionStatus>(
    useCallback(async () => {
      if (!winnerAddress) return closedAuction;

      try {
        const winningBids = await web3Provider.marketplace?.winningBids(
          artPiece.marketplaceVersion,
          artPiece.tokenId
        );

        if (!winningBids) return closedAuction;

        const { winner, complete, price } = winningBids;

        const lastAuctionIsCurrentWinner =
          winner &&
          winner.toLowerCase() === winnerAddress.toLowerCase() &&
          winningPrice === ethers.utils.formatEther(price);

        // The last approved auction has not been paid.
        if (!complete) {
          return {
            isApprovedWinner: lastAuctionIsCurrentWinner,
            isAuctionComplete: false,
          };
        }

        return {
          isApprovedWinner: lastAuctionIsCurrentWinner,
          isAuctionComplete: lastAuctionIsCurrentWinner,
        };
      } catch (error) {
        console.error(error);
        return closedAuction;
      }
    }, [artPiece, winnerAddress, winningPrice]),
    "",
    [artPiece.tokenId, winnerAddress]
  );
  return response;
};

export const useCheckApproval = (
  ainsophVersion: AinsophVersion,
  marketplaceVersion: MarketplaceVersion,
  address?: string
) => {
  return useAsync<boolean>(
    useCallback(async () => {
      const checkAddress = address || web3Provider.selectedAddress;

      return web3Provider.ainsoph?.checkApproval(
        ainsophVersion,
        checkAddress,
        web3Provider.marketplace?.contracts[marketplaceVersion].address
      );
    }, [ainsophVersion, marketplaceVersion, address]),
    "",
    []
  );
};
