import { Link, RouteComponentProps } from "@reach/router";
import React, { FunctionComponent, useMemo, useState } from "react";

import { SEO } from "~/components/Routing/SEO";
import { useLoadArtists } from "~/lib/firestore/collections";

import { Loading } from "../Loading/Loading";
import { MarketHeader } from "../Market/MarketHeader";

import "./ArtistListPage.css";

export type ArtistPageProps = RouteComponentProps<{
  artistSlug: string;
}>;

export const ArtistListPage: FunctionComponent<RouteComponentProps> = () => {
  const [artistsLoaded, artists] = useLoadArtists();
  const [search, setSearch] = useState("");

  const artistList = useMemo(() => Object.values(artists), [artists]);

  const filteredArtists = useMemo(
    () =>
      artistList
        .filter((artist) => {
          if (!search) return true;
          return artist.name.toLowerCase().includes(search.toLowerCase());
        })
        .sort((artist1, artist2) => {
          return artist1.name.localeCompare(artist2.name);
        }),
    [artistList, search]
  );

  if (!artistsLoaded) return <Loading />;

  const illustArtist = artistList.find((artist) => artist.slug === "illust");

  return (
    <div>
      <SEO title="Artists" />

      <MarketHeader topHeader="Artists" />

      <input
        className="artist-list-page__search"
        placeholder="Search"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      {filteredArtists.map((artist) => {
        return (
          <Link
            key={artist.id}
            to={`/nft-art/${artist.slug}`}
            className="artist-list-item"
          >
            <img
              className="artist-list-item__banner"
              src={artist.bannerUrl || illustArtist.bannerUrl}
              alt={artist.name}
            />
            <h2 className="artist-list-item__name">{artist.name}</h2>
          </Link>
        );
      })}
    </div>
  );
};
