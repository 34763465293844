import { useCallback, useEffect, useState } from "react";

export const useAsync = <T>(
  asyncAction: () => Promise<T>,
  errorValue: string,
  deps: unknown[]
): [T | null, string] => {
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<string>("");

  const action = useCallback(asyncAction, [...deps, asyncAction]);

  useEffect(() => {
    action()
      .then(setValue)
      .catch(() => setError(errorValue));
  }, [setValue, action, asyncAction, errorValue]);

  return [value, error];
};
