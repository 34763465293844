import React, { FunctionComponent, useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import axios from "axios";
import { AssetMetadata } from "~/types";
import { api } from "~/api";
import { assetPath, modelViewerPath } from "~/lib/routes";

export type RouteNormalizerProps = RouteComponentProps;

/** Convert the old anchor paths to new routes. */
export const RouteNormalizer: FunctionComponent<RouteComponentProps> = ({
  location,
  children,
}) => {
  const { hash } = location;

  useEffect(() => {
    if (!hash) return;

    const loadPath = async () => {
      const normalizedPath = await hashRouteToPath(hash);

      if (normalizedPath) {
        navigate(normalizedPath, { replace: true });
      }
    };

    loadPath();
  }, [hash]);

  return <>{children}</>;
};

// Handles the old elements.js routes.
const hashRouteToPath = async (hash: string) => {
  const [route, params] = hash.split("?");
  const queryParams = new URLSearchParams(params);

  if (route === "#") {
    return "/";
  }
  if (route === "#market") {
    if (queryParams.has("live")) return "/nft-art/live";
    if (queryParams.has("featured")) return "/nft-art/featured";

    const creator = queryParams.get("creator");

    if (creator) {
      if (creator === "DOOM") {
        return "/nft-art/mf-doom";
      }

      if (creator === "Brian&20Ziff") {
        return "/nft-art/brian-ziff";
      }

      return `/nft-art/${creator.toLowerCase()}`;
    }

    return "/";
  }
  if (route === "#editAssets") {
    return "/admin/assets";
  }
  if (route === "#editAsset") {
    return `/admin/assets/${params}`;
  }
  if (route === "#account") {
    return "/account";
  }
  if (route === "#asset") {
    try {
      // Get the asset by ID, and use its slug for the correct route.
      const response = await axios.get<AssetMetadata>(
        `${api}/metadata/${params}`
      );
      return assetPath(response.data);
    } catch (error) {
      return "/404";
    }
  }
  if (route === "#gallery") {
    try {
      // Get the asset by ID, and use its slug for the correct route.
      const response = await axios.get<AssetMetadata>(
        `${api}/metadata/${params}`
      );
      return modelViewerPath(response.data);
    } catch (error) {
      return "/404";
    }
  }
  if (route === "#404") {
    return "/404";
  }
  return "";
};
