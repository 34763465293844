/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IllustCustody, IllustCustodyInterface } from "../IllustCustody";

const _abi = [
  {
    inputs: [
      {
        internalType: "bool",
        name: "enable",
        type: "bool",
      },
    ],
    name: "allowMinting",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "asset",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
      {
        internalType: "address payable",
        name: "winner",
        type: "address",
      },
    ],
    name: "listAsset",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenID",
        type: "uint256",
      },
      {
        internalType: "address payable",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "royaltyReceiver1",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "royaltyReceiver2",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "royaltySplitPercentage",
        type: "uint8",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "asset",
        type: "uint256",
      },
    ],
    name: "pay",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "AinsophContractAddress",
        type: "address",
      },
    ],
    name: "setAinsophContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "royaltyReceiver",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "royaltyPercentage",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "royaltySplitPercentage",
        type: "uint8",
      },
    ],
    name: "setDefaultRoyalties",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "asset",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "enabled",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "firstSale",
        type: "bool",
      },
      {
        internalType: "uint8",
        name: "percentage",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "initialRoyaltyPercentage",
        type: "uint8",
      },
    ],
    name: "setRoyalties",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "asset",
        type: "uint256",
      },
    ],
    name: "setTokenPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address payable",
        name: "reciever",
        type: "address",
      },
    ],
    name: "widthdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "assets",
    outputs: [
      {
        internalType: "bool",
        name: "enabled",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "firstSale",
        type: "bool",
      },
      {
        internalType: "uint8",
        name: "initialRoyaltyPercentage",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "royaltyPercentage",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "asset",
        type: "uint256",
      },
    ],
    name: "getRoyaltyPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenContract",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "winnigBids",
    outputs: [
      {
        internalType: "bool",
        name: "enabled",
        type: "bool",
      },
      {
        internalType: "address payable",
        name: "winner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "complete",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IllustCustody__factory {
  static readonly abi = _abi;
  static createInterface(): IllustCustodyInterface {
    return new utils.Interface(_abi) as IllustCustodyInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IllustCustody {
    return new Contract(address, _abi, signerOrProvider) as IllustCustody;
  }
}
