import React, { FunctionComponent } from "react";
import { navigate } from "@reach/router";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { emptyUserProfile, UserProfile } from "@shared/models/UserProfile";

import { SEO } from "~/components/Routing/SEO";
import { account } from "~/account";
import { getErrorMessage } from "~/lib/error";

import "./CreateAccount.css";

const EMAIL_REGEX = /.+@.+\..+/;

export const CreateAccount: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: emptyUserProfile,
  });

  const handleSave = async (info: UserProfile) => {
    try {
      await account.update(info);
      navigate("/account");
      window.gtag("event", "sign_up");
    } catch (error) {
      console.error("Create error", error);
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <>
      <SEO title="Create Account" />
      <form
        className="popup create-account__popup"
        onSubmit={handleSubmit(handleSave)}
      >
        <h2 className="create-account__heading">Welcome to Illust Space:</h2>
        <br />
        Please choose a name to get started
        <br />
        <br />
        <label className="create-account__label" htmlFor="usernamei">
          Username*
        </label>
        <input
          id="usernamei"
          className="create-account__input"
          {...register("username", { required: true })}
        />
        {errors.username && <span>Required</span>}
        <label className="create-account__label" htmlFor="firstname">
          First Name*
        </label>
        <input
          id="firstname"
          className="create-account__input"
          {...register("firstName", { required: true })}
        />
        <label className="create-account__label" htmlFor="lastname">
          Last Name*
        </label>
        <input
          id="lastname"
          className="create-account__input"
          {...register("lastName", { required: true })}
        />
        <label className="create-account__label" htmlFor="emaili">
          Email*
        </label>
        <input
          id="emaili"
          className="create-account__input"
          {...register("email", {
            required: true,
            pattern: EMAIL_REGEX,
          })}
        />
        <label className="create-account__label" htmlFor="bioi">
          Bio
        </label>
        <textarea
          id="bioi"
          className="create-account__input"
          placeholder="bio (optional)"
          {...register("bio")}
        />
        <br />
        <label className="create-account__checkboxLabel">
          <input
            type="checkbox"
            {...register("verifiedTos", { required: true })}
            className="create-account__input create-account__checkbox"
          />
          *I agree to the
          <a href="https://illust.space/terms-of-service">Terms of Service</a>,
          the
          <a href="https://illust.space/privacy-policy">Privacy Policy</a>, and
          the <a href="https://illust.space/user-agreement">User Agreement</a>
        </label>
        {errors.verifiedTos && (
          <span>Please agree to the Terms of Service</span>
        )}
        <label className="create-account__checkboxLabel">
          <input
            type="checkbox"
            className="create-account__input create-account__checkbox"
            {...register("allowEmails")}
          />
          <span>
            Yes, I would like to receive email communications from Illust.Space
          </span>
        </label>
        <span className="create-account__text">
          Email will be used to notify auction winners.
        </span>
        <span className="create-account__text">*Required Fields</span>
        <button type="submit" className="button--block create-account__button">
          Create Account
        </button>
        <button
          type="button"
          className="create-account__connectChange"
          onClick={() => account.logout()}
        >
          Connect through different method
        </button>
      </form>
    </>
  );
};
