import React, { FunctionComponent, useState } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";

import { web3Provider } from "~/provider";

import { useAuctionStatus } from "./hooks/useIsWinnerApproved";
import { transactionToast } from "~/components/toasts/TransactionToast";

export interface WinnerFinishedAuctionActionsProps {
  assetId: string;
  metadata: ArtPiece;
}

export const WinnerFinishedAuctionActions: FunctionComponent<
  WinnerFinishedAuctionActionsProps
> = ({ assetId, metadata }) => {
  const [paying, setPaying] = useState(false);
  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );
  const auctionStatus = useAuctionStatus(
    metadata,
    selectedAddress,
    metadata.price
  );

  const handlePayNow = async () => {
    setPaying(true);

    const tx = await web3Provider.marketplace?.pay(
      metadata.marketplaceVersion,
      assetId,
      metadata.price
    );

    transactionToast(`Transferring ${metadata.name}`, tx);
  };

  if (!auctionStatus) return null;

  const { isApprovedWinner, isAuctionComplete } = auctionStatus;

  if (isAuctionComplete) return null;

  return (
    <>
      Congrats, you have won this auction.
      <br />
      {isApprovedWinner ? (
        <button
          type="button"
          className="button--block"
          disabled={paying}
          onClick={handlePayNow}
        >
          Pay Now
        </button>
      ) : (
        <div>
          A pay now button will appear here once the seller has confirmed the
          sale.
        </div>
      )}
    </>
  );
};
