import React, { FunctionComponent } from "react";
import { account } from "~/account";

export const AddressChangedToast: FunctionComponent = () => {
  return (
    <div>
      We've detected an address change. Would you like to:
      <div>
        <button
          type="button"
          className="button--block"
          onClick={() => account.addAddressToAccount()}
        >
          Add to your account
        </button>
        <button
          type="button"
          className="button--block"
          onClick={() => account.loginWithCurrentProvider()}
        >
          Switch Accounts
        </button>
        <button
          type="button"
          className="button--block"
          onClick={() => account.logout()}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
