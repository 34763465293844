import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";

import { useWatchArtPieces, useLoadArtist } from "~/lib/firestore/collections";

import { Loading } from "../Loading/Loading";
import { SEO } from "../Routing/SEO";
import { CollectionItemGroup } from "./CollectionItemGroup";

import "./Market.css";
import { sortByEndDate } from "~/lib/filter-art-pieces";

export const MarketFeatured: FunctionComponent<RouteComponentProps> = () => {
  const [loadedArtPieces, artPieces] = useWatchArtPieces();
  const [illustArtistLoaded, illustArtist] = useLoadArtist("illust");

  if (!(loadedArtPieces && illustArtistLoaded)) return <Loading />;

  const filteredArtPieces = sortByEndDate(
    Object.values(artPieces).filter((artPiece) => artPiece.featured)
  );

  return (
    <>
      <SEO title="Featured" image={illustArtist?.bannerUrl} />

      <CollectionItemGroup
        title="Featured"
        banner={illustArtist?.bannerUrl}
        bannerAlt="Featured Pieces"
        artPieces={filteredArtPieces}
        isFirst
      />
    </>
  );
};
