import axios from "axios";
import React, { FormEvent, FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "./SellAsset.css";

import { account } from "~/account";
import { api } from "~/api";
import { getErrorMessage } from "~/lib/error";

export interface SellAssetProps {
  assetId: string;
  onClose: () => void;
}

export const SellAsset: FunctionComponent<SellAssetProps> = ({
  assetId,
  onClose,
}) => {
  const [date, setDate] = useState(
    () =>
      new Date(`${new Date().toString().split("GMT")[0]} UTC`)
        .toISOString()
        .split(".")[0]
  );
  const [reserve, setReserve] = useState("0.0000");

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await beginAuction(assetId, date, reserve);
    onClose();
  };

  return (
    <>
      <h2>Create Auction</h2>
      <form onSubmit={handleSubmit}>
        <label className="auction__label" htmlFor="js-end_date">
          End Date
          <DatePicker
            calendarClassName="illust-date-picker"
            popperPlacement="bottom"
            placeholderText="Auction End Date"
            showTimeSelect
            timeIntervals={15}
            onChange={(value) => {
              const firstDate = Array.isArray(value) ? value[0] : value;
              setDate(firstDate?.toISOString() || "");
            }}
            selected={date ? new Date(date) : null}
            dateFormat="MM/dd/yyyy hh:mm aa"
            customInput={<input className="auction__input" />}
          />
        </label>

        <label className="auction__label" htmlFor="js-start_price">
          Reserve
        </label>
        <input
          className="auction__input"
          type="number"
          step="0.002"
          value={reserve}
          onChange={(event) => setReserve(event.target.value)}
        />

        <button type="submit" className="button--block">
          Begin Auction
        </button>
      </form>
      <button type="button" className="button--blank" onClick={onClose}>
        Cancel
      </button>
    </>
  );
};

const beginAuction = async (
  tokenId: string,
  date: string,
  reserve: string
): Promise<void> => {
  const message = {
    tokenId,
    endDate: new Date(date).toISOString(),
    startPrice: reserve,
  };
  const body = {
    message,
    sig: await account.sign(JSON.stringify(message)),
  };

  try {
    const response = await axios.post(`${api}/market/sell`, body);
    toast.dark(response.data);
  } catch (error) {
    toast.error(`Can't sell: ${getErrorMessage(error)}`);
  }
};
