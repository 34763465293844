import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { ethers } from "ethers";

import { useWatchArtPiece } from "~/lib/firestore/collections";
import { web3Provider } from "~/provider";

import { Loading } from "~/components/Loading/Loading";
import { OpenEdition } from "./MarketplaceAssetOpenEdition";

import "./MarketplaceAsset.css";
import { LotPageDetails } from "./LotPageDetails";
import { LotPageAuction } from "./LotPageAuction";

export type MarketplaceAssetOpenEditionLotProps = RouteComponentProps<{
  assetSlug: string;
  editionNumber: string;
}>;

export const MarketplaceAssetOpenEditionLot: FunctionComponent<
  MarketplaceAssetOpenEditionLotProps
> = ({ assetSlug, editionNumber }) => {
  const [openEdition, setOpenEdition] = useState<OpenEdition | null>(null);

  const [artPieceLoaded, artPiece, artPieceError] = useWatchArtPiece(assetSlug);

  useEffect(() => {
    if (!artPiece?.tokenId) return;

    web3Provider.marketplace.contracts.v3
      .openEditions(artPiece.tokenId)
      .then(setOpenEdition);
  }, [artPiece, setOpenEdition]);

  const tokenId = artPiece?.tokenId
    ? ethers.BigNumber.from(artPiece.tokenId).add(editionNumber).toString()
    : "";

  const [, auctionArtPiece] = useWatchArtPiece(tokenId, "tokenId");

  if (artPieceError) return <div>{artPieceError}</div>;
  if (!(artPieceLoaded && openEdition)) return <Loading />;

  const socialMessage = `Check out the ${artPiece.name}${
    artPiece.artist ? ` by ${artPiece.artist}` : ""
  } AR NFT in the Illust Space Marketplace`;

  return (
    <LotPageDetails
      title={`${artPiece.name} (OE-${editionNumber})`}
      socialMessage={socialMessage}
      artPiece={artPiece}
      tokenId={tokenId}
    >
      <LotPageAuction
        artPiece={auctionArtPiece}
        ainsophVersion={artPiece.ainsophVersion}
        tokenId={tokenId}
      />
    </LotPageDetails>
  );
};
