import { Link } from "@reach/router";
import React, { FunctionComponent } from "react";
import { toast } from "react-toastify";

import { assetUrl } from "@shared/assets";

import { account, useCurrentUser } from "~/account";
import { getErrorMessage } from "~/lib/error";
import { useSidebarStore } from "~/lib/sidebar";

import "./Header.css";

export const Header: FunctionComponent = () => {
  const [, , info] = useCurrentUser();
  const setOpenSidebar = useSidebarStore((state) => state.setOpen);

  const handleWeb3 = async () => {
    localStorage.setItem("provider", "web3");
    try {
      await account.login();
    } catch (error) {
      if (error !== "Modal closed by user") {
        toast.error(getErrorMessage(error));
      }
    }
  };

  const loginContents = (
    <>
      <img
        className="header__user-icon"
        src={`${assetUrl("icons/account.svg")}`}
        alt=""
      />
      <p className="header__username">
        {info ? info.username || info.email || "No Username" : "Sign In to Bid"}
      </p>
    </>
  );

  return (
    <div className="header">
      <div className="header__top-line">
        <div className="header__login-wrapper">
          {info ? (
            <Link className="header__account" to="/account">
              {loginContents}
            </Link>
          ) : (
            <button
              type="button"
              className="button header__login"
              onClick={handleWeb3}
            >
              Log in to bid
            </button>
          )}
        </div>

        <Link className="header__illust" to="/nft-art/featured">
          ILLUST.SPACE
        </Link>

        <div className="header__menu-wrapper">
          <button
            type="button"
            className="header__menu button--blank"
            onClick={() => setOpenSidebar(true)}
          >
            <div className="header__menu-line" />
            <div className="header__menu-line" />
            <div className="header__menu-line" />
          </button>
        </div>
      </div>

      <div className="br header__br" />
    </div>
  );
};
