import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "@reach/router";

import { UserProfile } from "@shared/models/UserProfile";
import { ArtPiece } from "@shared/models/ArtPiece";
import { findOpenEdition } from "@shared/findOpenEdition";

import { Loading } from "~/components/Loading/Loading";
import { useCurrentUser } from "~/account";
import { CollectionItem } from "~/components/Market/CollectionItem";
import { useWatchArtPieces } from "~/lib/firestore/collections";
import { web3Provider } from "~/provider";
import { MapCollection } from "./MapCollection";

export const UserCollection: FunctionComponent = () => {
  const [, , userProfile] = useCurrentUser();
  const collection = useCollection(userProfile);
  const mapCollection = useMappedCollection(collection);

  if (!collection) return <Loading />;

  if (!collection.length) {
    return (
      <div className="user-collection__start-collecting-wrapper">
        You have don't have a collection yet
        <Link className="user-collection__start-collecting-button" to="/">
          <button type="button" className="button--block">
            Start Collecting
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="profileAssets__collection">
      <MapCollection artPieces={collection} mapPieces={mapCollection} />
      {collection.map((asset) => (
        <div key={asset.id} className="profileAssets__collectionItem">
          <CollectionItem artPiece={asset} />
        </div>
      ))}
    </div>
  );
};

const useCollection = (userProfile: UserProfile): ArtPiece[] | null => {
  const [artPiecesLoaded, artPieces] = useWatchArtPieces();
  const [collection, setCollection] = useState<ArtPiece[] | null>(null);

  useEffect(() => {
    if (!(userProfile && artPiecesLoaded)) return;

    const load = async () => {
      // Get owned assets for every address.
      const tokenMatrix = await Promise.all(
        userProfile?.addresses?.eth.map((address) =>
          web3Provider.ainsoph?.getOwnedTokenIds(address)
        )
      );
      const tokenIds = flatten(tokenMatrix);
      const artPieceList = Object.values(artPieces);

      const openEditions = artPieceList.filter(
        (artPiece) => artPiece.isOpenEdition
        // (artPiece) => artPiece.showInMarketplace === true
      );

      setCollection(
        tokenIds
          .map((tokenId) => {
            const matchingPiece = artPieceList.find(
              (artPiece) => artPiece.tokenId === tokenId
            );
            return matchingPiece || findOpenEdition(tokenId, openEditions);
          })
          .filter((artPiece) => !!artPiece)
      );
    };
    load();
  }, [userProfile, artPiecesLoaded, artPieces, setCollection]);

  return collection;
};

const flatten = <T,>(xss: T[][]): T[] => {
  return xss.reduce((final, xs) => final.concat(xs), []);
};

const useMappedCollection = (
  collection: Array<ArtPiece>
): ArtPiece[] | null => {
  const [artPiecesLoaded, artPieces] = useWatchArtPieces();
  const [mapCollection, setMappedCollection] = useState<ArtPiece[] | null>(
    null
  );

  useEffect(() => {
    if (!artPiecesLoaded || !collection) return;
    const load = async () => {
      const artPieceList = Object.values(artPieces);

      // find all artpieces that are on the map && not in the owners collection
      const mappedArtPiecesList = artPieceList.filter((artPiece) => {
        // default to not showing  piece on map
        let shouldShowOnMap = false;

        // check for coordinates & public map
        if (
          artPiece.showOnMap &&
          artPiece.coordinates.latitude &&
          artPiece.coordinates.longitude
        ) {
          shouldShowOnMap = true;

          // hide item if it is in owner collection
          collection.forEach((collectionPiece) => {
            shouldShowOnMap = collectionPiece.id !== artPiece.id;
          });
        }
        return shouldShowOnMap;
      });

      setMappedCollection(mappedArtPiecesList);
    };
    load();
  }, [collection, artPiecesLoaded, artPieces, setMappedCollection]);
  return mapCollection;
};
