import { useEffect, useState } from "react";

export const useTimeLeft = (endDate: string): number => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!endDate) {
      setTimeLeft(0);
      return undefined;
    }

    // Reset the time left when the endDate changes
    setTimeLeft(new Date(endDate).getTime() - new Date(Date.now()).getTime());

    // Update every second
    const interval = setInterval(() => {
      setTimeLeft((time) => time - 1000);
    }, 1000);

    // Record the timer to cancel later.
    setTimer(interval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [endDate, setTimeLeft, setTimer]);

  // End the timer when the countdown reaches 0.
  useEffect(() => {
    if (!endDate) return;

    if (timeLeft <= 0 && timer) {
      clearInterval(timer);
    }
  }, [timeLeft, endDate, timer]);

  return timeLeft;
};

export const getTimeLeftMessage = (timeLeft: any): string => {
  if (timeLeft <= 0) return "";
  const daysRemaining = Math.floor(timeLeft / 86400000);
  const hoursRemaining = Math.floor((timeLeft % 86400000) / 3600000);
  const minutesRemaining = Math.floor((timeLeft % 3600000) / 60000);
  const secondsRemaining = Math.floor((timeLeft % 60000) / 1000);

  const daysMessage = daysRemaining ? `${daysRemaining} Days ` : "";

  return `${daysMessage}${hoursRemaining
    .toString()
    .padStart(2, "0")}:${minutesRemaining
    .toString()
    .padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")}`;
};
