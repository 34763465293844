import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

import { UserProfile } from "@shared/models/UserProfile";

export interface ProfileInfoFormProps {
  accountInfo: UserProfile;
  onSave: (info: UserProfile) => void;
  onCancel: () => void;
}

const EMAIL_REGEX = /.+@.+\..+/;

export const ProfileInfoForm: FunctionComponent<ProfileInfoFormProps> = ({
  accountInfo,
  onSave,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: accountInfo,
  });

  const handleSave = handleSubmit(onSave);

  return (
    <form onSubmit={handleSave}>
      <label className="profileInfo__userAttribute profileInfo__userAttribute--edit">
        Username
        <input {...register("username", { required: true })} />
        {errors.username && <span>Required</span>}
      </label>

      <label className="profileInfo__userAttribute profileInfo__userAttribute--edit">
        Email
        <input
          {...register("email", {
            required: true,
            pattern: EMAIL_REGEX,
          })}
        />
        {errors.email && <span>Invalid Email</span>}
      </label>

      <label className="profileInfo__userAttribute profileInfo__userAttribute--edit">
        First Name
        <input {...register("firstName", { required: true })} />
        {errors.firstName && <span>Required</span>}
      </label>

      <label className="profileInfo__userAttribute profileInfo__userAttribute--edit">
        Last Name
        <input {...register("lastName", { required: true })} />
        {errors.lastName && <span>Required</span>}
      </label>

      <label className="profileInfo__userAttribute profileInfo__userAttribute--edit">
        Bio
        <textarea placeholder="bio (optional)" {...register("bio")} />
      </label>

      <div className="profileInfo__form-buttons">
        <button type="submit" className="button--block">
          Save
        </button>

        <button
          type="button"
          className="button--block profileInfo__logout"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
