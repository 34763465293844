import React, { FunctionComponent } from "react";
import { Router } from "@reach/router";

// import { Market } from "../Market/Market";
import { UserProfile } from "../Account/UserProfile/UserProfile";
import { Connect } from "../Account/Connect/Connect";
import { MarketplaceAsset } from "../Asset/MarketplaceAsset";
import { NotFound } from "../NotFound";
import { RouteNormalizer } from "./RouteNormalizer";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { ArtistListPage } from "../Artist/ArtistListPage";
import { MarketLive } from "../Market/MarketLive";
import { MarketFeatured } from "../Market/MarketFeatured";
import { MarketArtist } from "../Market/MarketArtist";
import { MarketplaceAssetOpenEditionLot } from "../Asset/MarketplaceAssetOpenEditonLot";

export const Routes: FunctionComponent = () => {
  return (
    <Router>
      <RouteNormalizer path="/">
        <MarketFeatured path="/" />
        <ArtistListPage path="/nft-art" />
        <MarketLive path="/nft-art/live" />
        <MarketFeatured path="/nft-art/featured" />
        <MarketArtist path="/nft-art/:artistSlug" />

        <MarketplaceAsset path="/nft-art/:artistSlug/:assetSlug" />
        <MarketplaceAssetOpenEditionLot path="/nft-art/:artistSlug/:assetSlug/:editionNumber" />

        <Connect path="/login" />

        <AuthenticatedRoutes path="/">
          <UserProfile path="/account" />
          <NotFound default />
        </AuthenticatedRoutes>

        <NotFound default />
      </RouteNormalizer>

      <NotFound default />
    </Router>
  );
};
