import React, { FunctionComponent } from "react";
import { Link } from "@reach/router";

import { UserProfile } from "@shared/models/UserProfile";

import "./Sidebar.css";
import { getEnv } from "@shared/env";

export const adminAddress = () => getEnv("adminAddress").toLowerCase();

export interface SidebarLinksProps {
  user?: UserProfile;
  //eslint-disable-next-line react/no-unused-prop-types
  selectedAddress?: string;
  onLogout: () => void;
}

export const SidebarLinks: FunctionComponent<SidebarLinksProps> = ({
  user,
  onLogout,
}) => {
  return (
    <div className="sidebar">
      <div>
        <Link to="/account">Account</Link>
      </div>
      <div>
        <Link to="/nft-art/featured">Market</Link>
      </div>
      <div>
        <a
          href="https://illust.space/nft-art-auctions"
          target="_blank"
          rel="noreferrer"
        >
          Exhibitions
        </a>
      </div>
      <div>
        <a href="https://docs.illust.space" target="_blank" rel="noreferrer">
          Documents
        </a>
      </div>

      {user && (
        <div>
          <button
            type="button"
            className="sidebar__button button--blank"
            onClick={onLogout}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};
