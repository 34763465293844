import { ArtPiece } from "@shared/models/ArtPiece";
import { AinsophVersion } from "@shared/contracts/wrappers/Ainsoph";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Loading } from "~/components/Loading/Loading";
import { useWatchBids } from "~/lib/firestore/collections";
import { web3Provider } from "~/provider";
import { UsernameAddress } from "../UsernameAddress";
import { AuctionDetails } from "./AuctionDetails/AuctionDetails";
import { SellAsset } from "./SellAsset";

export interface LotPageAuctionProps {
  artPiece: ArtPiece;
  ainsophVersion: AinsophVersion;
  tokenId: string;
}

export const LotPageAuction: FunctionComponent<LotPageAuctionProps> = ({
  artPiece,
  ainsophVersion,
  tokenId,
}) => {
  const [isSellingAsset, setIsSellingAsset] = useState(false);
  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );

  const owner = useOwner(ainsophVersion, tokenId);

  const [loadedBids, bids] = useWatchBids(tokenId);

  if (!loadedBids) return <Loading />;

  return (
    <>
      {isSellingAsset ? (
        <SellAsset assetId={tokenId} onClose={() => setIsSellingAsset(false)} />
      ) : (
        <AuctionDetails
          assetId={tokenId}
          metadata={artPiece}
          owner={owner}
          bids={bids}
        />
      )}
      {owner && (
        <div>
          Owner: <UsernameAddress address={owner} />
        </div>
      )}
      {owner && owner === selectedAddress && !isSellingAsset && (
        <button
          type="button"
          className="button--block"
          onClick={() => setIsSellingAsset(true)}
        >
          List item in marketplace
        </button>
      )}
    </>
  );
};

const useOwner = (ainsophVersion: AinsophVersion, tokenId: string): string => {
  const [owner, setOwner] = useState("");

  useEffect(() => {
    if (!(tokenId && ainsophVersion)) return;

    web3Provider.ainsoph?.getOwner(ainsophVersion, tokenId).then(setOwner);
  }, [tokenId, ainsophVersion]);

  return owner ? owner.toLowerCase() : "";
};
