import React, { FunctionComponent } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";

import { LotPageDetails } from "./LotPageDetails";
import { LotPageAuction } from "./LotPageAuction";

export interface MarketplaceAssetSingleEditionProps {
  artPiece: ArtPiece;
}

export const MarketplaceAssetSingleEdition: FunctionComponent<
  MarketplaceAssetSingleEditionProps
> = ({ artPiece }) => {
  const socialMessage = `Check out the ${artPiece.name}${
    artPiece.artist ? ` by ${artPiece.artist}` : ""
  } AR NFT in the Illust Space Marketplace`;

  return (
    <LotPageDetails
      title={artPiece.name}
      socialMessage={socialMessage}
      artPiece={artPiece}
      tokenId={artPiece.tokenId}
    >
      <LotPageAuction
        artPiece={artPiece}
        tokenId={artPiece.tokenId}
        ainsophVersion={artPiece.ainsophVersion}
      />
    </LotPageDetails>
  );
};
