import { AinsophVersion } from "@shared/contracts/wrappers/Ainsoph";
import { getEnv } from "@shared/env";
import { web3Provider } from "~/provider";
import { getEtherscanNetworkLink } from "./network";

export const assetPath = (asset: {
  artistSlug?: string;
  slug?: string;
  id?: string;
  openEditionIndex?: number;
}) => {
  const path = `/nft-art/${asset.artistSlug || "illust"}/${
    asset.slug || asset.id
  }`;
  if (asset.openEditionIndex) {
    return `${path}/${asset.openEditionIndex}`;
  }
  return path;
};

/** Lot page for a specific open edition version. */
export const openEditionAssetPath = (
  asset: {
    artistSlug?: string;
    slug?: string;
    id?: string;
  },
  version: number
) => {
  return `/nft-art/${asset.artistSlug || "illust"}/${
    asset.slug || asset.id
  }/${version}`;
};

export const artistPath = (artistSlug: string) => `/nft-art/${artistSlug}`;

export const editAssetPath = (assetId: string) => `/admin/assets/${assetId}`;

export const worldTrackingPreviewPath = (artPiece: {
  artistSlug?: string;
  slug: string;
}) => {
  return `${getEnv("webarRoot")}/web/${artPiece.artistSlug || "illust"}/${
    artPiece.slug
  }`;
};

export const faceTrackingPath = (artPiece: {
  artistSlug?: string;
  slug: string;
}) => {
  return `${getEnv("webarRoot")}/face/${artPiece.artistSlug || "illust"}/${
    artPiece.slug
  }`;
};

export const modelViewerPath = (asset: { slug: string }) => {
  return `${getEnv("webarRoot")}/native/${asset.slug}/test`;
};

export const artPieceTokenPath = (
  ainsophVersion: AinsophVersion,
  tokenId: string
) => {
  return getEtherscanNetworkLink(
    web3Provider.provider?.network?.chainId,
    `token/${web3Provider.ainsoph?.contracts[ainsophVersion].address}?a=${tokenId}`
  );
};
