import React, { FunctionComponent } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";
import { Bid } from "@shared/models/Bid";

import { FinishedAuctionActions } from "./FinishedAuctionActions";
import { UsernameAddress } from "~/components/UsernameAddress";

export interface FinishedAuctionDetailsProps {
  assetId: string;
  metadata: ArtPiece;
  bids: Bid[];
  owner: string;
}

export const FinishedAuctionDetails: FunctionComponent<
  FinishedAuctionDetailsProps
> = ({ assetId, metadata, bids, owner }) => {
  const { activeAuction } = metadata;

  const endTime = new Date(activeAuction?.endDate);

  const endTimePretty = endTime.toLocaleString();
  const latestPrice = bids[0]?.amount || "";

  return (
    <>
      <div className="auction__label">This auction has ended</div>
      <div className="auction__attribute">{endTimePretty}</div>
      <div className="auction__label">Top Bidder</div>
      <div className="auction__attribute">
        {activeAuction?.topBidder && (
          <UsernameAddress address={activeAuction.topBidder} />
        )}
      </div>
      {latestPrice && (
        <>
          <div className="auction__label">Closing Price</div>
          <div className="auction__attribute" id="priceBox">
            {latestPrice} ETH
          </div>
        </>
      )}

      <FinishedAuctionActions
        assetId={assetId}
        metadata={metadata}
        owner={owner}
      />
    </>
  );
};
