import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { toast } from "react-toastify";

import { useCurrentUser } from "~/account";
import { LoginLink } from "~/components/LoginLink/LoginLink";
import { useWatchArtPiece } from "~/lib/firestore/collections";
import { web3Provider } from "~/provider";

import { Loading } from "../Loading/Loading";
import { MarketplaceAssetSingleEdition } from "./MarketplaceAssetSingleEdition";
import {
  MarketplaceAssetOpenEdition,
  OpenEdition,
} from "./MarketplaceAssetOpenEdition";
import { NotFound } from "../NotFound";

export type MarketplaceAssetProps = RouteComponentProps<{
  assetSlug: string;
}>;

export const MarketplaceAsset: FunctionComponent<MarketplaceAssetProps> = ({
  assetSlug,
}) => {
  const [, , info] = useCurrentUser();
  const [openEdition, setOpenEdition] = useState<OpenEdition | null>(null);

  useLoggedOutMessage(!!info);

  const [artPieceLoaded, artPiece, artPieceError] = useWatchArtPiece(assetSlug);

  useEffect(() => {
    if (!(artPiece?.tokenId && web3Provider.ainsoph)) return undefined;

    const getOpenEdition = () => {
      web3Provider.marketplace.contracts.v3
        .openEditions(artPiece.tokenId)
        .then(setOpenEdition)
        .catch(() =>
          setOpenEdition({ wasOpen: false, isOpen: false } as OpenEdition)
        );
    };

    web3Provider.ainsoph?.on(
      artPiece.ainsophVersion,
      "Transfer",
      getOpenEdition
    );

    getOpenEdition();

    return () => {
      web3Provider.ainsoph?.off(
        artPiece.ainsophVersion,
        "Transfer",
        getOpenEdition
      );
    };
  }, [artPiece?.ainsophVersion, artPiece?.tokenId]);

  if (artPieceError) return <div>{artPieceError}</div>;
  if (!(artPieceLoaded && openEdition)) return <Loading />;
  if (!artPiece || !artPiece.showInMarketplace) {
    return <NotFound message={`${assetSlug} not found`} />;
  }

  return openEdition?.wasOpen ? (
    <MarketplaceAssetOpenEdition
      artPiece={artPiece}
      openEdition={openEdition}
    />
  ) : (
    <MarketplaceAssetSingleEdition artPiece={artPiece} />
  );
};

const useLoggedOutMessage = (isLoggedIn: boolean) => {
  useEffect(() => {
    if (isLoggedIn) return null;

    const timeout = setTimeout(() => {
      toast.dark(<LoginLink>Create an account to place bids</LoginLink>, {
        toastId: "asset-login",
      });
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoggedIn]);
};
