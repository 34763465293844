/* eslint-disable global-require, import/no-dynamic-require, @typescript-eslint/no-var-requires */
import productionEnv from "./env.production.json";
import stagingEnv from "./env.staging.json";
import developmentEnv from "./env.development.json";
import testEnv from "./env.test.json";

type EnvName = "development" | "staging" | "production" | "test";
const environmentNames = ["development", "staging", "production", "test"];

/** The type of the environment variables, determined by env.prod.json */
type Env = typeof productionEnv & {
  environment: EnvName;
};

/** Pull in the correct environment configuration for the current environment. */
export function setUpEnvData(): Env {
  // Get local gitignored overrides.
  const localOverrides = getLocalOverrides();

  // Get the environment name from env vars, falling back to the local default.
  const environment = getFinalEnvironmentName(localOverrides);

  // Get the current environment config.
  const currentEnvVars = getCurrentEnvironmentConfig(environment);

  /** Optional overrides from process.env */
  const envOverrides = getEnvironmentVariableOverrides();

  // Don't use local overrides in test mode.
  if (currentEnvVars.lockOverrides) {
    return {
      ...(productionEnv as Env),
      ...currentEnvVars,
    };
  }

  // Merge env.json files, starting with prod
  const finalConfig = {
    ...(productionEnv as Env),
    ...currentEnvVars,
    ...localOverrides,
    ...envOverrides,
  };

  return finalConfig;
}

export const envVars = setUpEnvData();

export function getEnv<T extends keyof Env>(key: T): Env[T] {
  return envVars[key];
}

/** Pull a boolean from a string, only if it is defined. */
function booleanFromString(value: string | undefined): boolean | undefined {
  if (value === "true") return true;
  if (value === "false") return false;
  return undefined;
}

/** Get the env name from a FIREBASE_CONFIG json env var */
function getEnvironmentNameFromFirebaseConfig(): EnvName | "" {
  if (!process.env.FIREBASE_CONFIG) return "";

  const firebaseConfigVars = JSON.parse(process.env.FIREBASE_CONFIG);
  if (firebaseConfigVars.isTest === true) {
    return "test";
  } else if (firebaseConfigVars.projectId === "demo-illust") {
    return "development";
  } else if (firebaseConfigVars.projectId === "illust-marketplace-staging") {
    return "staging";
  } else if (firebaseConfigVars.projectId === "illust") {
    return "production";
  } else {
    return "";
  }
}

/** Figure out the environment name from env vars, falling back to the local defaul */
function getFinalEnvironmentName(localOverrides: Partial<Env>): EnvName {
  const environment = // Start with the env.local.json
    // then pull from env vars
    process.env.ENVIRONMENT ||
    process.env.NEXT_PUBLIC_ENVIRONMENT ||
    process.env.PUBLIC_ENVIRONMENT ||
    getEnvironmentNameFromFirebaseConfig() ||
    localOverrides.environment ||
    process.env.NODE_ENV ||
    "none";

  if (!isEnvironmentNameValid(environment)) {
    throw new Error(`Invalid environment name: ${environment}`);
  }

  // Log the environment for easier debugging.
  // eslint-disable-next-line no-console
  console.info("Environment:", environment);

  return environment;
}

/** Get local gitignored overrides. */
function getLocalOverrides(): Partial<Env> {
  try {
    return require("./env.local.json");
  } catch (e) {
    return {};
  }
}

/** Get overrides from specific env vars, but only if they are defined */
function getEnvironmentVariableOverrides(): Partial<Env> {
  const overrides: Partial<Env> = {
    useFirebaseEmulator: booleanFromString(
      process.env.USE_FIREBASE_EMULATOR ||
        process.env.NEXT_PUBLIC_FIREBASE_EMULATOR
    ),
    adminAddress: process.env.PUBLIC_ADMIN_ADDRESS as string,
    ainsoph2Address: process.env.PUBLIC_CONTRACT_ADDRESS as string,
    marketplace2Address: process.env.PUBLIC_CUSTODY_ADDRESS as string,
  };

  Object.keys(overrides).forEach((k) => {
    const key = k as keyof Env;

    if (overrides[key] === undefined) {
      delete overrides[key];
    }
  });

  return overrides;
}

/** Returns the configuration for the named environment  */
function getCurrentEnvironmentConfig(environment: EnvName): Partial<Env> {
  if (environment === "production") {
    return productionEnv as Env;
  } else if (environment === "staging") {
    return stagingEnv as Env;
  } else if (environment === "development") {
    return developmentEnv as Env;
  } else if (environment === "test") {
    return testEnv as Env;
  }
  return {};
}

/** Type guard for valid env names */
function isEnvironmentNameValid(
  environmentName: string
): environmentName is EnvName {
  return environmentNames.includes(environmentName);
}
