import React, { ChangeEvent, FunctionComponent } from "react";
import { Link, navigate } from "@reach/router";

import { artistPath } from "~/lib/routes";
import { useLoadArtists } from "~/lib/firestore/collections";

import "./MarketNav.css";

export const MarketNav: FunctionComponent = () => {
  const [artistsLoaded, artists] = useLoadArtists();

  const artistList = Object.values(artists).sort((artist1, artist2) => {
    return artist1.name.localeCompare(artist2.name);
  });

  const handleTagChange = (event: ChangeEvent<HTMLSelectElement>) => {
    updateTagUrl(event.target.value);
  };

  return (
    <nav>
      <ul id="js-subhead-nav" className="subhead__nav">
        <li className="subhead__navItem">
          <Link to="/nft-art/live">Live</Link>
        </li>
        <li className="subhead__navItem">
          <Link to="/nft-art/featured">Featured</Link>
        </li>
        <li className="subhead__navItem">
          <select
            className="subhead__tagSelect"
            onChange={handleTagChange}
            name="tag filter"
            defaultValue=""
          >
            <option disabled value="">
              Artist
            </option>
            {artistsLoaded &&
              artistList.map(({ slug, name }) => (
                <option key={slug} value={slug}>
                  {name}
                </option>
              ))}
          </select>
        </li>
      </ul>
    </nav>
  );
};

const updateTagUrl = (artistSlug: string): void => {
  navigate(artistPath(artistSlug));
};
