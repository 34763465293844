import { navigate, RouteComponentProps } from "@reach/router";
import React, { FunctionComponent, useEffect } from "react";

import { useCurrentUser } from "~/account";

import { Loading } from "../Loading/Loading";

/** Lock routes to only logged-in users. */
export const AuthenticatedRoutes: FunctionComponent<RouteComponentProps> = ({
  children,
}) => {
  const [isLoading, currentUser] = useCurrentUser();

  useEffect(() => {
    if (!isLoading && !currentUser) {
      navigate("/login");
    }
  }, [isLoading, currentUser]);

  if (isLoading) return <Loading />;

  return <>{children}</>;
};
