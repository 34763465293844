import React, { FunctionComponent, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useCurrentUser } from "~/account";

export const Intercom: FunctionComponent = () => {
  const { boot, update } = useIntercom();
  const [isLoadingUser, , accountInfo] = useCurrentUser();
  useEffect(() => {
    if (!isLoadingUser && accountInfo) {
      const updatedIntercomData = {
        ...(accountInfo.firstName && { name: accountInfo.firstName }),
        ...(accountInfo.email && { email: accountInfo.email }),
        ...(accountInfo.username && { user_id: accountInfo.username }),
      };
      update(updatedIntercomData);
    }
    boot({
      backgroundColor: "#181818",
      actionColor: "#a47a44",
    });
  }, [isLoadingUser, accountInfo, boot, update]);
  return <></>;
};
