import React, { FunctionComponent } from "react";

import { UserProfile } from "@shared/models/UserProfile";
import { account } from "~/account";

export interface ProfileInfoStaticProps {
  accountInfo: UserProfile;
  onEdit: () => void;
}

export const ProfileInfoStatic: FunctionComponent<ProfileInfoStaticProps> = ({
  accountInfo,
  onEdit,
}) => {
  const { username, email, firstName, lastName, bio } = accountInfo;

  const handleConnect = async () => {
    await account.connectAnotherAddress(accountInfo);
  };

  return (
    <div>
      <button type="button" className="profileInfo__userName" onClick={onEdit}>
        @{username}
      </button>
      <button
        type="button"
        className="profileInfo__userAttribute"
        onClick={onEdit}
      >
        <strong>Email:</strong>
        <span>{email}</span>
      </button>
      <button
        type="button"
        className="profileInfo__userAttribute"
        onClick={onEdit}
      >
        <strong>First Name:</strong>
        <span>{firstName}</span>
      </button>
      <button
        type="button"
        className="profileInfo__userAttribute"
        onClick={onEdit}
      >
        <strong>Last Name:</strong>
        <span>{lastName}</span>
      </button>
      <button
        type="button"
        className="profileInfo__userAttribute"
        onClick={onEdit}
      >
        <strong>Bio:</strong>
        <span>{bio || "Welcome to my page, this is my bio"}</span>
      </button>

      <button type="button" className="button--block" onClick={handleConnect}>
        Switch Wallets
      </button>
    </div>
  );
};
