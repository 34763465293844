import React, { FunctionComponent } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";
import { Bid } from "@shared/models/Bid";

import { ActiveAuctionDetails } from "./ActiveAuctionDetails";
import { FinishedAuctionDetails } from "./FinishedAuctionDetails";

import { useTimeLeft } from "~/lib/timeRemaining";

import "./AuctionDetails.css";

export interface AuctionDetailsProps {
  assetId: string;
  metadata: ArtPiece;
  owner: string;
  bids: Bid[];
}

export const AuctionDetails: FunctionComponent<AuctionDetailsProps> = ({
  assetId,
  metadata,
  owner,
  bids,
}) => {
  const endDate = metadata?.activeAuction?.endDate;
  const timeLeft = useTimeLeft(endDate);

  if (endDate) {
    return timeLeft > 0 ? (
      <ActiveAuctionDetails
        metadata={metadata}
        bids={bids}
        owner={owner}
        timeLeft={timeLeft}
      />
    ) : (
      <FinishedAuctionDetails
        assetId={assetId}
        metadata={metadata}
        bids={bids}
        owner={owner}
      />
    );
  }

  return <>This asset is not for sale</>;
};
