import React, { useEffect, FunctionComponent, useState } from "react";
import { Link } from "@reach/router";
import QRCode from "qrcode.react";

import { getCurrentNetworkName } from "~/lib/network";
import { web3Provider } from "~/provider";
import { account } from "~/account";
import { copyText } from "~/lib/copyText";
import { useCheckApproval } from "~/components/Asset/AuctionDetails/hooks/useIsWinnerApproved";
import { ApproveTransfers } from "~/components/Asset/AuctionDetails/ApproveTransfers";

import "./WalletInfo.css";

export const WalletInfo: FunctionComponent = () => {
  const balance = useBalance();
  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );

  const [approved] = useCheckApproval("v3", "v3");

  // const bids = Object.entries(account.info.bids || {});
  // TODO: Fetch bids
  const bids = [];

  return (
    <>
      <div className="wallet__label">Active Wallet Address:</div>
      <button
        type="button"
        className="wallet__address button--plain"
        onClick={() => copyText(selectedAddress, "Copied address")}
      >
        {selectedAddress}
      </button>

      {approved === false && (
        <div className="wallet__approve-wrapper">
          <ApproveTransfers
            message="You must approve illust to transact tokens on your behalf before selling NFTs."
            ainsophVersion="v3"
            marketplaceVersion="v3"
          />
        </div>
      )}

      <div className="wallet__label">Balance:</div>
      <div className="wallet__attribute" id="balBox">
        {balance === null ? "Loading" : `${balance} ETH`}
      </div>
      {bids.length ? (
        <>
          <div className="wallet__label">Bids:</div>
          <div className="wallet__attribute" id="bidList">
            {bids.map(([i, bid]) => (
              <>
                <Link to={`/assets/${i}`}>{i}</Link>
                {bid}
                <br />
              </>
            ))}
          </div>
        </>
      ) : null}
      <div className="wallet__label">Wallet ENS Name:</div>
      <div className="wallet__attribute">None Claimed</div>
      <div className="wallet__label">Network:</div>
      <div className="wallet__attribute">{getCurrentNetworkName()}</div>
      <div className="wallet__label">Provider:</div>
      <div className="wallet__attribute">{web3Provider.getProviderName()}</div>
      <div className="wallet__label">{/* todo */}</div>
      <div id="qrcode">
        <QRCode value={selectedAddress} />
      </div>
    </>
  );
};

const useBalance = (): string => {
  const [balance, setBalance] = useState<string | null>(null);

  useEffect(() => {
    account.getBal().then(setBalance);
  }, []);

  return balance;
};
