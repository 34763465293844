import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "@reach/router";
import { BigNumber, ethers } from "ethers";

import { ArtPiece } from "@shared/models/ArtPiece";

import { toast } from "react-toastify";
import { openEditionAssetPath } from "~/lib/routes";
import { web3Provider } from "~/provider";

import { transactionToast } from "../toasts/TransactionToast";
import { ApproveTransfers } from "./AuctionDetails/ApproveTransfers";
import { useCheckApproval } from "./AuctionDetails/hooks/useIsWinnerApproved";
import { LotPageDetails } from "./LotPageDetails";
import { UsernameAddress } from "../UsernameAddress";
import { getTimeLeftMessage, useTimeLeft } from "~/lib/timeRemaining";
import { TimerVisual } from "~/lib/TimerVisual";

export interface OpenEdition {
  wasOpen: boolean;
  isOpen: boolean;
  index: number;
  max: BigNumber;
  artist: string;
  price: BigNumber;
}

export interface MarketplaceAssetOpenEditionProps {
  artPiece: ArtPiece;
  openEdition: OpenEdition;
}

export const MarketplaceAssetOpenEdition: FunctionComponent<
  MarketplaceAssetOpenEditionProps
> = ({ artPiece, openEdition }) => {
  const [hasArtistApproved] = useCheckApproval(
    artPiece.ainsophVersion,
    artPiece.marketplaceVersion,
    openEdition.artist
  );
  const [owners, setOwners] = useState<string[] | null>(null);

  const endDate = artPiece?.activeAuction?.endDate;
  const timeLeft = useTimeLeft(endDate);
  const timeLeftMessage = getTimeLeftMessage(timeLeft);

  useEffect(() => {
    if (!artPiece?.tokenId) return;

    web3Provider.ainsoph
      ?.listOpenEditions(
        artPiece.ainsophVersion,
        artPiece.tokenId,
        openEdition.index
      )
      .then(setOwners);
  }, [artPiece.ainsophVersion, artPiece.tokenId, openEdition.index]);

  const socialMessage = `You can get a version of the ${artPiece.name}${
    artPiece.artist ? ` by ${artPiece.artist}` : ""
  } AR NFT in the Illust Space Marketplace`;

  const handleMintOpenEdition = async () => {
    if (!web3Provider.selectedAddress) {
      toast.error(
        "Please login or create an account by clicking account button in the top left corner to submit a bid"
      );
      return;
    }

    const tx = await web3Provider?.marketplace.mintOpenEdition(
      "v3",
      artPiece.tokenId,
      openEdition.price
    );

    transactionToast("Minting your open edition NFT", tx);
  };

  const mustApprove = hasArtistApproved !== null && !hasArtistApproved;

  const canMint =
    !mustApprove &&
    openEdition.isOpen &&
    timeLeft > 0 &&
    (openEdition.max.eq(0) || openEdition.max.gt(openEdition.index));

  return (
    <LotPageDetails
      title={`${artPiece.name} (Open Edition)`}
      socialMessage={socialMessage}
      artPiece={artPiece}
    >
      {mustApprove && (
        <ApproveTransfers
          ainsophVersion={artPiece.ainsophVersion}
          marketplaceVersion={artPiece.marketplaceVersion}
          message="Please approve transfers so collectors can mint"
        />
      )}

      {canMint && (
        <>
          <div className="auction__label">Time Remaining</div>
          <div className="auction__attribute" id="countdownBox">
            {timeLeftMessage}
            <TimerVisual timeRemaining={timeLeft} />
          </div>

          <div className="auction__label"># Minted</div>
          <div className="auction__attribute" id="priceBox">
            {openEdition.index}
          </div>

          <div className="auction__label">Price</div>
          <div className="auction__attribute" id="priceBox">
            {ethers.utils.formatEther(openEdition.price)} ETH
          </div>

          <button
            type="button"
            className="button"
            onClick={handleMintOpenEdition}
          >
            Buy Now
          </button>
        </>
      )}

      {!!owners?.length && (
        <>
          <h4>Owners</h4>
          {owners.map((owner, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <Link to={openEditionAssetPath(artPiece, index + 1)}>
                {index + 1}: <UsernameAddress address={owner} />
              </Link>
            </div>
          ))}
        </>
      )}
    </LotPageDetails>
  );
};
