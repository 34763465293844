import { BigNumberish, ContractTransaction, ethers } from "ethers";
import type { Web3Provider } from "@ethersproject/providers";

import { ArtPiece } from "@shared/models/ArtPiece";

import {
  Ainsoph__factory as Ainsoph2__factory,
  Ainsoph as Ainsoph2,
  Ainsoph3__factory,
  Ainsoph3,
} from "../abi";

interface Contracts {
  v2: Ainsoph2;
  v3: Ainsoph3;
}

export type AinsophVersion = keyof Contracts;

/** Wrapper for interaction with v2 and v3 Ainsoph contracts */
export class Ainsoph {
  contracts: Contracts;

  constructor(
    v2Address: string,
    v3Address: string,
    private provider: Web3Provider
  ) {
    this.contracts = {
      v2: Ainsoph2__factory.connect(v2Address, this.provider),
      v3: Ainsoph3__factory.connect(v3Address, this.provider),
    };
  }

  on(
    version: AinsophVersion,
    filter: any,
    listener: ethers.providers.Listener
  ) {
    this.contracts[version].on(filter, listener);
  }

  off(
    version: AinsophVersion,
    eventName: any,
    listener: ethers.providers.Listener
  ) {
    this.contracts[version].off(eventName, listener);
  }

  mintAsset(
    version: AinsophVersion,
    tokenId: string,
    ownerAddress: string,
    publicRoyaltyReceiverAddress: string,
    royaltySplits: {
      royaltyReceiver: string;
      royaltyPercentage: string | number;
    }[]
  ): Promise<ContractTransaction> {
    const tokenIdFormatted = ethers.BigNumber.from(tokenId).toString();

    if (version === "v2") {
      const contract = this.contracts[version].connect(
        this.provider.getSigner()
      );

      return contract.mintAsset(
        tokenId,
        ownerAddress,
        royaltySplits[0].royaltyReceiver,
        royaltySplits[1].royaltyReceiver,
        (royaltySplits || []).reduce(
          (split, royalty) =>
            split + ethers.BigNumber.from(royalty.royaltyPercentage).toNumber(),
          0
        )
      );
    }

    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.mintAsset(
      tokenIdFormatted,
      ownerAddress,
      publicRoyaltyReceiverAddress,
      royaltySplits || []
    );
  }

  setApproval(version: AinsophVersion, marketplaceContract: string) {
    const contract = this.contracts[version].connect(this.provider.getSigner());

    return contract.setApprovalForAll(marketplaceContract, true);
  }

  checkApproval(
    version: AinsophVersion,
    address: string,
    marketplaceContract: string
  ) {
    const contract = this.contracts[version];

    return contract.isApprovedForAll(address, marketplaceContract);
  }

  getOwner(version: AinsophVersion, tokenId: string) {
    const contract = this.contracts[version];
    return contract.ownerOf(tokenId);
  }

  /** Get the contract address from either the ainsoph, or NFT contract */
  getContractAddress(artPiece: ArtPiece): string {
    if (artPiece.contractAddress) {
      return artPiece.contractAddress;
    }

    return this.contracts[artPiece.ainsophVersion].address;
  }

  async getOwnedTokenIds(address: string): Promise<string[]> {
    const contractV2 = this.contracts.v2;
    const contractV3 = this.contracts.v3;

    const totalV2 = (await contractV2.balanceOf(address)).toNumber();
    const totalV3 = (await contractV3.balanceOf(address)).toNumber();

    const tokenPromises: Promise<string>[] = [];

    for (let i = 0; i < totalV2; i++) {
      tokenPromises.push(
        contractV2
          .tokenOfOwnerByIndex(address, i)
          .then((tokenId) => tokenId.toString())
      );
    }

    for (let i = 0; i < totalV3; i++) {
      tokenPromises.push(
        contractV3
          .tokenOfOwnerByIndex(address, i)
          .then((tokenId) => tokenId.toString())
      );
    }

    return Promise.all(tokenPromises);
  }

  /** Get the owner of all minted open editions. */
  async listOpenEditions(
    version: AinsophVersion,
    tokenId: string,
    index: BigNumberish
  ): Promise<string[]> {
    if (version === "v2") {
      throw new Error("not supported in v2");
    }

    const contract = this.contracts[version];

    const tokenPromises: Promise<string>[] = [];

    const tokenCount = ethers.BigNumber.from(index).toNumber();
    const tokenIdNumber = ethers.BigNumber.from(tokenId);

    for (let i = 1; i <= tokenCount; i++) {
      tokenPromises.push(contract.ownerOf(tokenIdNumber.add(i)));
    }

    return Promise.all(tokenPromises);
  }
}
