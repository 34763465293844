import React from "react";
import ReactDOM from "react-dom";
import "ethers";

import "./styles/dreams.css";
import "./styles/toasts.css";

import { App } from "./components/App";

ReactDOM.render(<App />, document.getElementById("root"));
