import React, { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";

import { UserProfile } from "@shared/models/UserProfile";
import { assetUrl } from "@shared/assets";

import { account, useCurrentUser } from "~/account";
import { getErrorMessage } from "~/lib/error";

import { ProfileInfoForm } from "./ProfileInfoForm";
import { ProfileInfoStatic } from "./ProfileInfoStatic";

import "./ProfileInfo.css";

export const ProfileInfo: FunctionComponent = () => {
  const [isLoadingUser, , accountInfo] = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = async (info: UserProfile) => {
    try {
      await account.update(info);
      setIsEditing(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  if (isLoadingUser) return null;

  return (
    <div className="profileInfo">
      <img
        alt="Profile Info"
        src={assetUrl("icons/account.svg")}
        className="profileInfo__photo"
      />

      {isEditing ? (
        <ProfileInfoForm
          accountInfo={accountInfo}
          onCancel={() => setIsEditing(false)}
          onSave={handleSave}
        />
      ) : (
        <ProfileInfoStatic
          accountInfo={accountInfo}
          onEdit={() => setIsEditing(true)}
        />
      )}

      <div className="profileInfo__userAttribute--readonly">
        <strong>Connected Ethereum Addresses:</strong>

        {accountInfo.addresses.eth.map((address) => (
          <div key={address} className="profile-info__address">
            {address}
          </div>
        ))}
      </div>

      <button
        type="button"
        className="button--block profileInfo__logout"
        onClick={() => account.logout()}
      >
        Sign Out
      </button>
    </div>
  );
};
