import React, { FunctionComponent } from "react";
import type { ContractTransaction } from "ethers";
import { toast } from "react-toastify";

import { getEtherscanNetworkLink } from "~/lib/network";

export interface TransactionToastProps {
  message: string;
  transaction: ContractTransaction;
}

export const TransactionToast: FunctionComponent<TransactionToastProps> = ({
  message,
  transaction,
}) => {
  const etherscanLink = getEtherscanNetworkLink(
    transaction.chainId,
    `tx/${transaction.hash}`
  );

  return (
    <>
      <span>{message}. See on </span>
      <a href={etherscanLink} target="_blank" rel="noreferrer">
        etherscan
      </a>
      .
    </>
  );
};

export const transactionToast = (
  message: string,
  transaction: ContractTransaction
): void => {
  toast.dark(<TransactionToast message={message} transaction={transaction} />, {
    autoClose: false,
    closeButton: true,
    closeOnClick: false,
  });
};
