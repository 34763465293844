import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";

import { useLoadArtist, useWatchArtPieces } from "~/lib/firestore/collections";
import { filterArtPiecesLive } from "~/lib/filter-art-pieces";

import { Loading } from "../Loading/Loading";
import { SEO } from "../Routing/SEO";
import { CollectionItemGroup } from "./CollectionItemGroup";

import "./Market.css";

export const MarketLive: FunctionComponent<RouteComponentProps> = () => {
  const [loadedArtPieces, artPieces] = useWatchArtPieces();
  const [illustArtistLoaded, illustArtist] = useLoadArtist("illust");

  if (!(loadedArtPieces && illustArtistLoaded)) return <Loading />;

  const filteredArtPieces = filterArtPiecesLive(artPieces);

  return (
    <>
      <SEO title="Live" image={illustArtist.bannerUrl} />

      <CollectionItemGroup
        title="Live"
        banner={illustArtist.bannerUrl}
        bannerAlt="Live Auctions"
        artPieces={filteredArtPieces}
        isFirst
      />
    </>
  );
};
