import React, { FunctionComponent } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";

import {
  useAuctionStatus,
  useCheckApproval,
} from "./hooks/useIsWinnerApproved";
import { UsernameAddress } from "~/components/UsernameAddress";
import { web3Provider } from "~/provider";
import { ApproveTransfers } from "./ApproveTransfers";
import { transactionToast } from "~/components/toasts/TransactionToast";

export interface OwnerFinishedAuctionActionsProps {
  assetId: string;
  metadata: ArtPiece;
}

export const OwnerFinishedAuctionActions: FunctionComponent<
  OwnerFinishedAuctionActionsProps
> = ({ assetId, metadata }) => {
  const { activeAuction } = metadata;

  const [approved] = useCheckApproval(
    metadata.ainsophVersion,
    metadata.marketplaceVersion
  );
  const auctionStatus = useAuctionStatus(
    metadata,
    activeAuction?.topBidder,
    metadata.price
  );

  if (!activeAuction?.topBidder || approved === null || !auctionStatus) {
    return null;
  }

  const { isApprovedWinner, isAuctionComplete } = auctionStatus;

  const handleAcceptPrice = async () => {
    const tx = await web3Provider.marketplace?.acceptBid(
      metadata.marketplaceVersion,
      assetId,
      metadata.price,
      activeAuction.topBidder
    );

    transactionToast("Accepting price", tx);
  };

  if (!approved) {
    return (
      <ApproveTransfers
        ainsophVersion={metadata.ainsophVersion}
        marketplaceVersion={metadata.marketplaceVersion}
        message="You have not yet approved illust to transact tokens on your behalf:"
      />
    );
  }

  const basicData = (
    <>
      Winner:
      <UsernameAddress address={activeAuction.topBidder} />
      <br />
      Price: {metadata.price}
    </>
  );

  if (isAuctionComplete) {
    return basicData;
  }

  return (
    <>
      {basicData}
      {isApprovedWinner ? (
        <div className="auction__remit-message">
          Waiting on Buyer to remit funds
        </div>
      ) : (
        <button
          type="button"
          className="button--block"
          onClick={handleAcceptPrice}
        >
          Accept final price
        </button>
      )}
    </>
  );
};
