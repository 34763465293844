import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";

import { filterArtPiecesByArtist } from "~/lib/filter-art-pieces";
import { useWatchArtPieces, useLoadArtist } from "~/lib/firestore/collections";

import { Loading } from "../Loading/Loading";
import { SEO } from "../Routing/SEO";
import { NotFound } from "../NotFound";
import { CollectionItemGroup } from "./CollectionItemGroup";

import "./Market.css";

export type MarketArtistProps = RouteComponentProps<{ artistSlug: string }>;

export const MarketArtist: FunctionComponent<MarketArtistProps> = ({
  artistSlug,
}) => {
  const [loadedArtPieces, artPieces] = useWatchArtPieces();
  const [illustArtistLoaded, illustArtist] = useLoadArtist("illust");
  const [artistLoaded, artist] = useLoadArtist(artistSlug);

  if (!(loadedArtPieces && artistLoaded && illustArtistLoaded))
    return <Loading />;

  if (!artist) return <NotFound message="Artist not found" />;

  const artistArtPieces = filterArtPiecesByArtist(artist, artPieces);

  const banner = artist.bannerUrl || illustArtist.bannerUrl;

  return (
    <>
      <SEO title={artist.name} image={banner} />

      <CollectionItemGroup
        title={artist.name}
        banner={banner}
        bannerAlt={artist.name}
        artPieces={artistArtPieces}
        isFirst
      />
    </>
  );
};
