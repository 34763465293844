import { object, string, lazy, number, InferType } from "yup";

/** Coordinates that can be stored as a string or number. */
export const coordinatesSchema = object({
  latitude: lazy((value) =>
    typeof value === "number"
      ? number().required().min(-90).max(90).default(0)
      : string().required().default("")
  ),
  longitude: lazy((value) =>
    typeof value === "number"
      ? number().required().min(-180).max(180).default(0)
      : string().required().default("")
  ),
});

/** Coordinates that don't allow strings. */
export const strictCoordinatesSchema = object({
  latitude: number().min(-90).max(90).default(0),
  longitude: number().min(-180).max(180).default(0),
});

export const strictCoordinatesWithHashSchema = object({
  latitude: number().min(-90).max(90).default(0),
  longitude: number().min(-180).max(180).default(0),
  hash: string().default(""),
});

/** Standard coordinates description */
export interface Coordinates extends InferType<typeof coordinatesSchema> {}

/** Coordinates with a geohash */
export interface CoordinatesWithHash extends Coordinates {
  hash: string;
}

/** Standard coordinates description */
export interface StrictCoordinates
  extends InferType<typeof strictCoordinatesSchema> {}

/** Coordinates with a geohash */
export interface StrictCoordinatesWithHash extends StrictCoordinates {
  hash: string;
}

/** Default coordinates */
export const emptyCoordinates = strictCoordinatesSchema.getDefault();
