import React, { FunctionComponent } from "react";

import "~/components/Market/CollectionItem.css";

export interface TimerVisualProps {
  timeRemaining: number;
}

/** A small card that displays an asset. */
export const TimerVisual: FunctionComponent<TimerVisualProps> = ({
  timeRemaining,
}) => {
  return (
    <>
      {timeRemaining < 600_000 && (
        <div
          className="collectionItem__countdown"
          style={{ width: `${timeRemaining / 6000}% ` }}
        />
      )}
    </>
  );
};
