import React, { FunctionComponent, useEffect } from "react";
import { globalHistory } from "@reach/router";

import { account, useCurrentUser } from "~/account";
import { web3Provider } from "~/provider";

import { useSidebarStore } from "~/lib/sidebar";
import "./Sidebar.css";
import { SidebarLinks } from "./SidebarLinks";

export const Sidebar: FunctionComponent = () => {
  const isOpen = useSidebarStore((state) => state.open);
  const setOpen = useSidebarStore((state) => state.setOpen);
  const [, , info] = useCurrentUser();

  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );

  const handleLogout = () => account.logout();

  useEffect(() => {
    // Close on route change.
    return globalHistory.listen(() => setOpen(false));
  }, [setOpen]);

  if (!isOpen) return null;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className="sidebar__overlay" onClick={() => setOpen(false)} />

      <SidebarLinks
        user={info}
        selectedAddress={selectedAddress}
        onLogout={handleLogout}
      />
    </>
  );
};
