import React, { FunctionComponent } from "react";
import { Link, RouteComponentProps } from "@reach/router";

import { SEO } from "./Routing/SEO";

export interface NotFoundProps extends RouteComponentProps {
  message?: string;
}

export const NotFound: FunctionComponent<NotFoundProps> = ({
  message = "You've landed on a page that is broken or no longer in use.",
}) => {
  return (
    <>
      <SEO title="Not Found" />

      <div>
        <p>{message}</p>
      </div>

      <Link className="button inline-block" to="/">
        Back to home
      </Link>
    </>
  );
};
