import React, { FunctionComponent, useState } from "react";
import cx from "classnames";

import { UserCollection } from "./UserCollection";
import { WalletInfo } from "./WalletInfo";

import "./ProfileAssets.css";

type ActiveTab = "collection" | "wallet";

export const ProfileAssets: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>("collection");

  const contents =
    activeTab === "collection" ? <UserCollection /> : <WalletInfo />;

  return (
    <div className="profileAssets">
      <div className="profileAssets__header">
        <button
          type="button"
          id="cl"
          className={cx("profileAssets__headerItem button--blank", {
            "profileAssets__headerItem--current": activeTab === "collection",
          })}
          onClick={() => setActiveTab("collection")}
        >
          Collection
        </button>
        <button
          type="button"
          id="vw"
          className={cx("profileAssets__headerItem button--blank", {
            "profileAssets__headerItem--current": activeTab === "wallet",
          })}
          onClick={() => setActiveTab("wallet")}
        >
          View Wallet
        </button>
      </div>
      <div id="js-profileContents">{contents}</div>
    </div>
  );
};
