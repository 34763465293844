import { ethers } from "ethers";
import axios from "axios";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { navigate } from "@reach/router";

import { UserProfile } from "@shared/models/UserProfile";

import { api } from "./api";
import { web3Provider } from "./provider";
import { getErrorMessage } from "./lib/error";
import { app, db } from "./lib/firebase";
import { AddressChangedToast } from "./components/Account/AddressChangedToast";

declare global {
  interface Window {
    account: Account;
  }
}

class Account {
  collection: string[];

  /** Handle an address change. */
  async onAddressChange() {
    const userProfile = await fetchUserProfile();

    // Ignore changes when the user isn't logged in.
    if (!userProfile) return;

    if (userProfile.addresses?.eth.includes(web3Provider.selectedAddress)) {
      toast.dark(`Switched to ${web3Provider.selectedAddress}`);
    } else {
      // If the address isn't a known one, ask the user what to do.
      toast.dark(AddressChangedToast, {
        autoClose: false,
        position: "top-center",
      });
    }
  }

  /** Try to reconnect to the cached provider. */
  async reconnect() {
    await web3Provider.reconnect();
    const userProfile = await fetchUserProfile();

    // Logout if the current provider doesn't match the user.
    if (
      web3Provider.selectedAddress &&
      userProfile &&
      !userProfile.addresses?.eth.includes(web3Provider.selectedAddress)
    ) {
      this.logout();
    }
  }

  /** Log the user into firebase with a wallet signature. */
  async login(): Promise<firebase.User> {
    try {
      // Pop a modal to connect to a new or existing wallet provider.
      await web3Provider.connect();
      const user = await this.loginWithCurrentProvider();
      navigate("/account");

      return user;
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error));
      return null;
    }
  }

  async update(userProfile: UserProfile): Promise<UserProfile> {
    const idToken = await app.auth().currentUser.getIdToken();

    return axios.put(`${api}/users/profile`, userProfile, {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    });
  }

  async logout() {
    await app.auth().signOut();
    web3Provider.logout();
    navigate("/login");
  }

  /** Scrape the user's collection from their wallets. */
  async loadCollection() {
    const address = web3Provider.selectedAddress;
    const collection = await web3Provider.ainsoph?.getOwnedTokenIds(address);

    this.collection = isEmpty(collection) ? null : collection;
    return this.collection;
  }

  /** Add the current address to the current user. */
  async connectAnotherAddress(userProfile: UserProfile) {
    try {
      // Connect to a new provider.
      await web3Provider.connect();
      const newAddress = web3Provider.selectedAddress;
      if (userProfile.addresses?.eth.includes(newAddress)) {
        // If the new address is already associated with the current user, just switch
        toast.dark(`Switched to ${newAddress}`);
      } else {
        // Otherwise connect it to the user.
        await this.addAddressToAccount();
        toast.dark(`Added new address: ${newAddress}`);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      if (message === "address exists") {
        // If the address is associated with a different account, switch logins.
        toast.error(
          "Address is associated with a different account. Logging you in."
        );
        try {
          await this.loginWithCurrentProvider();
        } catch (loginError) {
          // If that fails, log out so the user isn't in a weird state
          // where the provider doesn't match the user.
          toast.error(getErrorMessage(loginError));
          this.logout();
        }
      } else {
        toast.error(message);
      }
    }
  }

  /** Sign a message with the current wallet. */
  async sign(msg: string): Promise<string> {
    try {
      // web3Provider.getSigner().signMessage(msg) doesn't work with coinbase
      const data = ethers.utils.toUtf8Bytes(msg);
      return web3Provider.provider.send("personal_sign", [
        ethers.utils.hexlify(data),
        web3Provider.selectedAddress,
      ]);
    } catch (e) {
      toast.error(`Could not sign message \n Got Error ${e}`);
      return "";
    }
  }

  /** Get the balances for the user's wallets. */
  async getBal(): Promise<string> {
    return ethers.utils.formatEther(
      await web3Provider.provider.getBalance(web3Provider.selectedAddress)
    );
  }

  isLoggedIn(): boolean {
    return !!app.auth().currentUser;
  }

  /**
   * Adds the current address to the user's account.
   * Needs both an auth token and a signature to prove the user owns
   * the address and the account.
   */
  async addAddressToAccount(): Promise<void> {
    const idToken = await app.auth().currentUser.getIdToken();
    const token = await fetchToken();
    const signature = await this.sign(`illust add address ${token}`);
    await axios.post(
      `${api}/users/profiles/eth/addresses/${web3Provider.selectedAddress}`,
      {
        signature,
      },
      {
        headers: {
          authorization: `Bearer ${idToken}`,
        },
      }
    );

    toast.dark(`Added address ${web3Provider.selectedAddress}`);
  }

  /** Trigger a login without showing the web3 provider modal. */
  async loginWithCurrentProvider() {
    // Get and sign a one-time token from the API.
    const token = await fetchToken();
    const signature = await this.sign(`illust login ${token}`);

    // Get a custom auth token from the API
    const loginResponse = await axios.post(`${api}/users/marketplace-login`, {
      address: web3Provider.selectedAddress,
      signature,
    });
    const authToken = loginResponse.data as string;

    // Log into firebase.
    const credentials = await app.auth().signInWithCustomToken(authToken);
    return credentials.user;
  }
}

/** Do a one-time fetch of the current user. */
const fetchUserProfile = async (): Promise<UserProfile | null> => {
  const { currentUser } = app.auth();

  if (!currentUser) return null;

  const profileSnapshot = await db
    .collection("users")
    .doc(currentUser.uid)
    .get();
  return profileSnapshot.data() as UserProfile;
};

/** Wrap the current firebase user in a react hook. */
export const useCurrentUser = (): [
  isLoadingUser: boolean,
  user: firebase.User,
  profile: UserProfile
] => {
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    return app.auth().onAuthStateChanged((currentUser) => {
      // Stop loading the user profile if there's no user.
      setIsLoadingProfile(!!currentUser);

      setUser(currentUser);
      setIsLoadingUser(false);
    });
  }, [setUser, setIsLoadingUser, setIsLoadingProfile]);

  useEffect(() => {
    if (isLoadingUser) return null;
    if (!user) {
      setProfile(null);
      return null;
    }

    return db
      .collection("users")
      .doc(user.uid)
      .onSnapshot((doc) => {
        setProfile(doc.data() as UserProfile);
        setIsLoadingProfile(false);
      });
  }, [isLoadingUser, user, setIsLoadingProfile]);

  return [isLoadingUser || isLoadingProfile, user, profile];
};

const fetchToken = async (): Promise<string> => {
  const tokenResponse = await axios.post(
    `${api}/users/${web3Provider.selectedAddress}/marketplace-token`
  );
  return tokenResponse.data as string;
};

const isEmpty = (object: any): boolean => !Object.keys(object).length;

export const account = new Account();
// Expose to HTML
window.account = account;
