import React, { FunctionComponent } from "react";

import { ArtPiece } from "@shared/models/ArtPiece";

import { web3Provider } from "~/provider";

import { OwnerFinishedAuctionActions } from "./OwnerFinishedAuctionActions";
import { WinnerFinishedAuctionActions } from "./WinnerFinishedAuctionAction";
import { useCurrentUser } from "~/account";

export interface FinishedAuctionActionsProps {
  assetId: string;
  metadata: ArtPiece;
  owner: string;
}

export const FinishedAuctionActions: FunctionComponent<
  FinishedAuctionActionsProps
> = ({ assetId, metadata, owner }) => {
  const [, , profile] = useCurrentUser();

  const { activeAuction } = metadata;

  const selectedAddress = web3Provider.useStore(
    (state) => state.selectedAddress
  );

  if (owner === selectedAddress) {
    return (
      <OwnerFinishedAuctionActions assetId={assetId} metadata={metadata} />
    );
  }

  if (
    activeAuction?.topBidder &&
    (activeAuction.topBidder.toLowerCase() === selectedAddress ||
      activeAuction.topBidderUsername === profile?.username)
  ) {
    return (
      <WinnerFinishedAuctionActions assetId={assetId} metadata={metadata} />
    );
  }

  return null;
};
